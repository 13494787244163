import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { ME_LIGHT } from '@bloomays-lib/adapter.api-bloomer';

import { Embed } from '../atoms/Embed';
import WithErrorBoundary from '../organisms/ErrorBoundary';

const FileViewer = (): JSX.Element => {
  const location = useLocation();
  const filePath = location?.pathname;

  if (!filePath) {
    throw new Error('Missing path params');
  }
  const { loading } = useQuery(ME_LIGHT);

  if (loading)
    return (
      <div>
        <p>Chargement du fichier ...</p>
      </div>
    );

  return !filePath.includes('talents/') ? (
    <Embed width="100%" height="1000px" src={`${import.meta.env.VITE_BLOOMERS_API}${filePath.substring(1)}`} />
  ) : (
    <Embed width="100%" height="1000px" src={`${import.meta.env.VITE_TALENTS_API}talent${filePath}`} />
  );
};

export { FileViewer };
export default WithErrorBoundary(FileViewer);
