import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ActionButton, TitlePart, Button, SingleLineText } from '@bloomays-lib/ui.shared';
import { FileRejection } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { GET_LEGAL_CONTRACTS_BY_MISSION, UPLOAD_AMENDMENT } from '@bloomays-lib/adapter.api-bloomer';
import { notify } from '../../helpers/toastify';
import {
  BrowserUploadFile,
  LegalContractGQL,
  UploadAmendmentGQLInput,
  UploadAmendmentGQLResult,
} from '@bloomays-lib/types.shared';
import { Upload } from './Upload';
import Modal from '../atoms/Modal';
import Checkbox from '../atoms/Checkbox';
import { isEmpty } from 'lodash/fp';
import DatePicker from '../atoms/DatePicker';
import { Upload as UploadGQL } from 'graphql-upload';
import { format } from 'date-fns/fp';

const disabledButton = (signedDate: Date | null, files: BrowserUploadFile[], signed: boolean) => {
  if (signed) {
    if (!signedDate) return true;
  }
  if (isEmpty(files)) return true;
  return false;
};

type UploadButtonProps = {
  legalContract: LegalContractGQL;
  signed: boolean;
  missionRecordId: string;
  createInvitation: boolean;
  setUploadingFile: (value: boolean) => void;
  onClickPreview: (file: string) => void;
};

const LegalContractFile = ({
  legalContract,
  signed,
  missionRecordId,
  onClickPreview,
  setUploadingFile,
}: UploadButtonProps) => {
  const { t } = useTranslation(['legalContracts']);
  const [uploadAmendment, { loading: uploadingFile, error: errorUpload }] = useMutation<UploadAmendmentGQLResult>(
    UPLOAD_AMENDMENT,
    {
      refetchQueries: [
        {
          query: GET_LEGAL_CONTRACTS_BY_MISSION,
          variables: {
            missionId: missionRecordId,
          },
        },
      ],
    },
  );
  const { recordId: legalContractRecordId, step } = legalContract;
  const [fileUrl, setFileUrl] = useState(signed ? legalContract.signedFileUrl : legalContract.emptyFileUrl);
  const [openPopin, setOpenPopin] = useState(false);
  const [createInvitation, setCreateInvitation] = useState(false);
  const [signedDate, setSignedDate] = useState<Date | null>(null);
  const [files, setFiles] = useState<BrowserUploadFile[]>([]);
  const contract = legalContract.step.includes('Prolongation') ? t('amendmant') : t('mainContract');
  const titlePage = t('uploadPopinTitle', { contract, contractType: legalContract.type });
  const uploadFileTitle = t('uploadFile');

  const upload = async () => {
    const input: UploadAmendmentGQLInput = {
      file: files[0] as UploadGQL,
      legalContractRecordId,
      signed,
      missionRecordId,
      createInvitation,
      signedDate: signedDate !== null ? format('yyyy-MM-dd', signedDate) : undefined,
    };
    const { data } = await uploadAmendment({
      variables: {
        input,
      },
    });
    if (data) {
      notify('success', 'Fichier uploadé avec succès.');
      const resultFileUrl = signed ? data.uploadAmendment.signedFileUrl : data.uploadAmendment.emptyFileUrl;
      setFileUrl(resultFileUrl as string);
    }
  };

  useEffect(() => {
    setUploadingFile(uploadingFile);
  }, [uploadingFile]);

  if (!fileUrl) {
    return (
      <>
        <Modal
          open={openPopin}
          onClose={() => {
            setOpenPopin(false);
            setFiles([]);
            setCreateInvitation(false);
          }}
          additionnalCSS={{
            width: '1250px',
            padding: '32px',
            border: 'none',
            borderRadius: '20px',
          }}
        >
          <ContainerTitle>
            <TitlePart textTitle={titlePage} />
          </ContainerTitle>
          {errorUpload && `⛔️ ERROR ${errorUpload}`}
          {fileUrl ? (
            t('uploadedFile')
          ) : (
            <>
              {step.includes('Prolongation') && !signed && (
                <ContainerCheckbox>
                  <Checkbox
                    name="createInvitation"
                    checked={createInvitation}
                    label={t('createInvitation')}
                    labelPlacement="start"
                    onChange={(e, checked) => {
                      setCreateInvitation(checked);
                    }}
                  />
                  {createInvitation && (
                    <ManualAmendmentAlert>
                      <InfoIcon />
                      <ManualAmendmentAlertMessage>
                        <ManualAmendmentAlertTitle>{t('manualAmendmentAlertTitle')}</ManualAmendmentAlertTitle>
                        <div dangerouslySetInnerHTML={{ __html: t('manualAmendmentAlertDescription') }}></div>
                      </ManualAmendmentAlertMessage>
                    </ManualAmendmentAlert>
                  )}
                </ContainerCheckbox>
              )}
              {signed && (
                <ContainerDate>
                  <SingleLineText text={t('signedDate')} />
                  <DatePicker
                    openTo="day"
                    views={['day']}
                    label={t('signedDate')}
                    handleChange={(e: Date | null) => e !== null && setSignedDate(e)}
                    value={signedDate}
                  />
                </ContainerDate>
              )}
              <Upload
                id={`input-${legalContractRecordId}`}
                placeholder={contract}
                textLabel={titlePage}
                name={`input-${legalContractRecordId}`}
                fileTypes={['.pdf']}
                maxFiles={1}
                maxLength={10000000}
                displayIcons={true}
                handleChange={(files: BrowserUploadFile[], rejectFiles: FileRejection[]) => {
                  if (rejectFiles.length > 0) {
                    const errors = rejectFiles
                      .map((rf: FileRejection) => {
                        return rf.errors.map((e) => e.message).join(', ');
                      })
                      .join(',');
                    notify('error', `Error uploading some files : ${errors}`);
                    return;
                  }
                  setFiles(files);
                }}
                autoload={true}
                noFeedback={true}
                preloadedFiles={files}
                noModal={true}
              />
              <ContainerButton>
                <Button
                  textButton={uploadFileTitle}
                  disable={disabledButton(signedDate, files, signed)}
                  onClick={async () => {
                    await upload();
                  }}
                />
              </ContainerButton>
            </>
          )}
        </Modal>
        <label htmlFor="file-input">
          <IconButton size="small" title={uploadFileTitle} onClick={() => setOpenPopin(true)}>
            <span role="img" aria-label="Icone pour attacher fichier">
              📎
            </span>
          </IconButton>
        </label>
      </>
    );
  }

  return (
    <ActionButton
      variant="contained"
      color="secondary"
      actionType={'Preview'}
      onClick={() => onClickPreview(fileUrl || '')}
      title={fileUrl}
    />
  );
};

export default LegalContractFile;

const ContainerCheckbox = styled('div')(() => ({
  textAlign: 'left',
}));

const ContainerDate = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ContainerTitle = styled('div')(() => ({
  paddingBottom: '50px',
}));

const ContainerButton = styled('div')(() => ({
  textAlign: 'right',
  paddingLeft: '50px',
  paddingTop: '20px',
}));

const ManualAmendmentAlert = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary[600],
  padding: '40px',
  borderRadius: '20px',
  marginBottom: '20px',
  color: '#5966BF',
  display: 'flex',
}));

const ManualAmendmentAlertMessage = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px',
}));

const ManualAmendmentAlertTitle = styled('div')(() => ({
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: 'large',
}));
