import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Modal from '../atoms/Modal';
import ExtensionShortenMission from './ExtensionShortenMission';
import {
  ExtensionAssessmentGQL,
  GetExtensionAssessmentGQLResult,
  IMissionCardDetails,
  Role,
} from '@bloomays-lib/types.shared';
import MissionDetailCard from './MissionDetailCard';
import { ModifyMission } from './ModifyMission';
import { ExtensionAssessment } from './ExtensionAssessment';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_MISSION,
  GET_EXTENSION_ASSESSMENT,
  UPSERT_EXTESION_ASSESSMENT,
} from '@bloomays-lib/adapter.api-bloomer';
import { dateFormat } from '@bloomays-lib/utils.shared';
import isUndefined from 'lodash/isUndefined';
import { compact } from 'lodash/fp';
import BasicTable from './BasicTable';
import { useAuth } from '../../auth/AuthProvider';
import DeleteActionPopin from './DeleteActionPopin';
import { useTranslation } from 'react-i18next';
import { notify } from '../../helpers/toastify';
import { LoaderPending } from '@bloomays-lib/ui.shared';

export type MissionCardProps = {
  mission: IMissionCardDetails; // TODO refactor --> ce type est faux ! le type de retour est lié à GET_ONE_FULL_MISSION !!
};

type PopinType = 'shorten' | 'extend' | 'modify' | 'assessment' | 'prices' | 'delete' | null;
type PopinState = {
  type: PopinType;
  show: boolean;
};
type MenuOption = {
  name: string;
  action: () => void;
};

const buildMenu = (
  navigate: NavigateFunction,
  mission: IMissionCardDetails,
  setOpen: Dispatch<SetStateAction<PopinState>>,
  isSystem: boolean,
): MenuOption[] => {
  return compact([
    {
      name: '👀 Voir les cra',
      action: () => navigate(`/missions/${mission.recordId}/activities`),
    },
    {
      name: '⚖️ Gérer les contrats',
      action: () => navigate(`/missions/${mission.recordId}/contracts`),
    },
    {
      name: '📝 Gérer les factures',
      action: () => navigate(`/mission/${mission.recordId}`),
    },
    mission.isActive
      ? {
          name: '😢 Écourter la mission',
          action: () => setOpen({ type: 'shorten', show: true }),
        }
      : null,
    mission.isActive
      ? {
          name: '🥳 Prolonger la mission',
          action: () => setOpen({ type: 'extend', show: true }),
        }
      : null,
    mission.isActive
      ? {
          name: '📝  Modifier la mission',
          action: () => setOpen({ type: 'modify', show: true }),
        }
      : null,
    mission.isActive
      ? {
          name: '🔮 Estimer la prolongation',
          action: () => setOpen({ type: 'assessment', show: true }),
        }
      : null,
    mission.prices
      ? {
          name: '👀 Historique des prix',
          action: () => setOpen({ type: 'prices', show: true }),
        }
      : null,
    isSystem
      ? {
          name: '🛑 Supprimer mission',
          action: () => setOpen({ type: 'delete', show: true }),
        }
      : null,
  ]);
};

const MissionCard = ({ mission }: MissionCardProps): JSX.Element => {
  const { t } = useTranslation(['random', 'mission']);
  const navigate = useNavigate();
  const auth = useAuth();
  const isSystem = auth?.user?.roles?.includes(Role.System) || false;
  const [open, setOpen] = useState<PopinState>({ type: null, show: false });
  const [pendingOperation, setPendingOperation] = useState<boolean>(false);

  const handleClose = () => {
    setOpen({ type: null, show: false });
  };

  const { data } = useQuery<GetExtensionAssessmentGQLResult>(GET_EXTENSION_ASSESSMENT, {
    variables: { missionId: mission.recordId },
  });
  const extensionAssessment: ExtensionAssessmentGQL | null = !isUndefined(data) ? data.getExtensionAssessment : null;
  const [upsertExtensionAssessmentMutation] = useMutation(UPSERT_EXTESION_ASSESSMENT);
  const upsertExtensionAssessment = async (missionId: string, assessment: number, newEndDate: Date): Promise<void> => {
    await upsertExtensionAssessmentMutation({
      variables: {
        input: {
          missionId,
          assessment,
          newEndDate: dateFormat(newEndDate, 'yyyy-MM-dd'),
        },
      },
      refetchQueries: [
        {
          query: GET_EXTENSION_ASSESSMENT,
          variables: { missionId: mission.recordId },
        },
      ],
    });
  };

  const [deleteMissionMutation, { loading: loadingDeleteMission, error: errorDeleteMission, data: deleteMissionData }] =
    useMutation(DELETE_MISSION);

  const deleteMission = async (): Promise<void> => {
    setOpen({ type: null, show: false });
    await deleteMissionMutation({
      variables: { missionId: mission.recordId },
    });
  };

  useEffect(() => {
    if (errorDeleteMission) {
      notify('error', errorDeleteMission.message);
    }
  }, [errorDeleteMission]);

  useEffect(() => {
    if (deleteMissionData) {
      navigate('/dashboard');
    }
  }, [deleteMissionData]);

  const actions = buildMenu(navigate, mission, setOpen, isSystem);

  return (
    <>
      <Modal additionnalCSS={{ width: '70%' }} open={open.show && open.type === 'shorten'} onClose={handleClose}>
        {pendingOperation ? <LoaderPending text="Attendez, ça prendra quelques secondes..." /> : null}
        <ExtensionShortenMission
          type="shorten"
          handleClose={handleClose}
          mission={mission}
          setPendingExtensionMission={setPendingOperation}
        />
      </Modal>
      <Modal additionnalCSS={{ width: '70%' }} open={open.show && open.type === 'extend'} onClose={handleClose}>
        {pendingOperation ? (
          <LoaderPending text="Attendez, ça prendra quelques secondes. Nous lançons le processus de prolongation de mission..." />
        ) : null}
        <ExtensionShortenMission
          type="extend"
          handleClose={handleClose}
          mission={mission}
          setPendingExtensionMission={setPendingOperation}
        />
      </Modal>
      <Modal additionnalCSS={{ width: '70%' }} open={open.show && open.type === 'modify'} onClose={handleClose}>
        {pendingOperation ? <LoaderPending text="Attendez, on actualise la mission..." /> : null}
        <ModifyMission mission={mission} handleClose={handleClose} setPendingMissionModify={setPendingOperation} />
      </Modal>
      <Modal additionnalCSS={{ width: '75%' }} open={open.show && open.type === 'assessment'} onClose={handleClose}>
        <ExtensionAssessment
          missionId={mission.recordId}
          currentEndDate={new Date(mission.endMission)}
          upsertExtensionAssessment={upsertExtensionAssessment}
          currentExtensionAssessment={extensionAssessment}
        />
      </Modal>
      <Modal additionnalCSS={{ width: '50%' }} open={open.show && open.type === 'prices'} onClose={handleClose}>
        👀 Historique des Prix
        <BasicTable columns={['Début de validité', 'Achat', 'Vente', 'TVA', 'Astreinte']}>
          {mission.prices &&
            mission.prices?.map((p, i) => (
              <tr key={`price-${i}`}>
                <td>{p.startDate?.toLocaleDateString() || ''}</td>
                <td>{`${p.purchasePricePerDayWithoutTaxes} €`}</td>
                <td>{`${p.salePricePerDayWithoutTaxes} €`}</td>
                <td>{`${p.taxes} %`}</td>
                <td>{`${p.astreinteCoef || 200} %`}</td>
              </tr>
            ))}
        </BasicTable>
      </Modal>
      <>
        {loadingDeleteMission && (
          <LoaderPending text="Attendez, ça prendra quelques secondes. Nous supprimons la mission d'airtable et les KPIs dans notre base..." />
        )}
        {open.show && open.type === 'delete' && (
          <DeleteActionPopin
            opened={open.show}
            onClose={handleClose}
            confirmLabel={t('deleteWarning', { ns: 'mission' })}
            deleteConfirmLabel={t('deleteWarning', { ns: 'mission' })}
            expectedValue="SUPPRIMER"
            deleteAction={() => {
              return deleteMission();
            }}
          />
        )}
      </>
      <MissionDetailCard
        type="mission"
        data={{ mission: mission, actions: actions, currentExtensionAssessment: extensionAssessment }}
      />
    </>
  );
};

export default MissionCard;
