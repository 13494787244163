import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import FilterTab from '../atoms/FilterTab';
import { useTranslation } from 'react-i18next';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import MultipleSelectSearch from './MultipleSelectSearch';
import { Item, ItemSkills } from '@bloomays-lib/types.shared';
import { Action, ActionType, State } from '../../types/talents';

export type FilterSkillProps = {
  onSearchSkill: (searchValue: string) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  skills: ItemSkills[];
  state: State;
  dispatch: React.Dispatch<Action>;
  allSkills?: ItemSkills[];
  setAllSkills: React.Dispatch<React.SetStateAction<ItemSkills[] | undefined>>;
  loadingSkills: boolean;
  searchSkillValue: string;
  setSearchSkillValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSkillsList: React.Dispatch<React.SetStateAction<ItemSkills[]>>;
  selectedSkillsList: ItemSkills[];
};

const FilterSkill = ({
  dispatch,
  skills,
  onSearchSkill,
  state,
  loadingSkills,
  allSkills,
  setAllSkills,
  setPage,
  searchSkillValue,
  setSearchSkillValue,
  selectedSkillsList,
  setSelectedSkillsList,
}: FilterSkillProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('talentTool');
  const globalStateSkills = state.search.skills?.skills;

  const onClickLevelSkill = (item: ItemSkills, level: number) => {
    const newskill: Item = { id: item.id, minLevel: level, label: item.label };
    setAllSkills(
      allSkills?.map((skill) => {
        if (skill.id === item.id) {
          dispatch({
            type: ActionType.ADD_SKILLS_SEARCH,
            skills: [newskill],
          });
          return { ...skill, selected: true, level };
        } else {
          return skill;
        }
      }),
    );
  };

  React.useEffect(() => {
    if (skills.length > 0) {
      setAllSkills(
        skills?.map((skill) => {
          return {
            ...skill,
            selected: globalStateSkills ? globalStateSkills?.some((GSkill) => skill.id === GSkill.id) : false,
          };
        }),
      );
    }
  }, [setAllSkills, skills]);

  React.useEffect(() => {
    if (globalStateSkills) {
      setSelectedSkillsList(globalStateSkills);
    }
  }, [globalStateSkills, setSelectedSkillsList]);

  const updateLocalState = (e: ItemSkills) => {
    const newskill: Item = {
      id: e.id,
      minLevel: e.level || 0,
      label: e.label,
    };
    let isSelected: boolean;
    setAllSkills(
      allSkills?.map((skill) => {
        if (skill.id === e.id) {
          isSelected = !skill?.selected;
          if (!isSelected) {
            dispatch({
              type: ActionType.RM_SKILLS_SEARCH,
              skillsId: [e.id],
            });
            return { ...skill, selected: isSelected, level: undefined };
          } else {
            dispatch({
              type: ActionType.ADD_SKILLS_SEARCH,
              skills: [newskill],
            });
            return { ...skill, selected: isSelected, level: e.level };
          }
        } else {
          return skill;
        }
      }),
    );
    setPage(1);
  };

  const updateLocalStateMultiple = (items: ItemSkills[], unSelect?: boolean) => {
    const newSkills: Item[] = items.map((item) => {
      return { id: item.id, minLevel: item.level || 0, label: item.label };
    });
    const allSkillsSelected = items.map((item) => {
      return { ...item, selected: true };
    });
    if (unSelect) {
      setAllSkills(allSkillsSelected);
      dispatch({
        type: ActionType.ADD_SKILLS_SEARCH,
        skills: newSkills,
      });
    } else {
      const idSkills = items.map((skill) => {
        return skill.id;
      });
      setAllSkills([]);
      dispatch({
        type: ActionType.RM_SKILLS_SEARCH,
        skillsId: idSkills,
      });
    }
    setPage(1);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, globalStateSkills]);

  return (
    <div>
      <FilterTab
        label={t('skill')}
        numberOfFilter={globalStateSkills?.length}
        onClick={() => {
          setOpen(!open);
        }}
        filled={false}
      />
      {open && (
        <StyledContainer ref={ref}>
          <StyledText text={t('skill')} variant={'subtitle2Medium'} />
          <StyledChildContainer>
            <StyledText text={t('skillRequired')} variant={'subtitle2Medium'} />
            <StyledList>
              <MultipleSelectSearch
                setSearch={setSearchSkillValue}
                search={searchSkillValue}
                loading={loadingSkills}
                onClickLevel={onClickLevelSkill}
                onSwitch={updateLocalState}
                items={allSkills}
                onSwitchMultiple={updateLocalStateMultiple}
                onSearch={onSearchSkill}
                selectedItemsList={selectedSkillsList}
                setSelectedItemsList={setSelectedSkillsList}
              />
            </StyledList>
          </StyledChildContainer>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterSkill;
export { FilterSkill };

const StyledContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 24px 24px 32px 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 528px;
  max-height: 426px;
  position: absolute;
  z-index: 1;
  overflow-X: auto;
`,
);

const StyledChildContainer = styled('div')(
  ({ theme }) => `
  background-color: ${theme.palette.paper.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 480px;
  border: 1.2px solid ${theme.palette.grey[400]};
  border-radius: 5px;
  `,
);

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
