import { TitlePart } from '@bloomays-lib/ui.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import DetailsSectionCard from '../molecules/DetailsSectionCard';
import { Button } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { months } from '../../helpers/CRA';
import { useEffect, useState } from 'react';
import { LabelAndInput } from '../molecules/LabelAndInput';

export type SummaryDaysWorkedProps = {
  month: string;
  daysWorked: number;
  daysAstreinte: number;
  daysMarkup: number;
  daysMarkup2: number;
  submitCRA: () => Promise<void>;
  handleClose: () => void;
};

const SummaryDaysWorked = ({
  month,
  daysWorked,
  daysAstreinte,
  daysMarkup,
  daysMarkup2,
  submitCRA,
  handleClose,
}: SummaryDaysWorkedProps): JSX.Element => {
  const [loadingButtonSubmit, setLoadingButtonSubmit] = useState<boolean>(false);
  const [hasMarkup, setHasMarkup] = useState<boolean>(daysMarkup > 0 || daysMarkup2 > 0 || daysAstreinte > 0);
  const [checked, SetChecked] = useState<boolean>(!hasMarkup);
  useEffect(() => {
    setHasMarkup(daysMarkup > 0 || daysMarkup2 > 0 || daysAstreinte > 0);
  }, [daysMarkup, daysMarkup2, daysAstreinte]);
  const date = new Date(month);
  const monthNumber = date.getMonth();

  const y = date.getFullYear();
  const m = months[monthNumber]?.name;

  const total = daysWorked + daysMarkup + daysMarkup2 + daysAstreinte;
  const wordDay = total !== 0 ? 'jours' : 'jour';

  const wordDayWeek = Number(daysWorked.toFixed(3)) <= 1 ? 'jour' : 'jours';
  const wordDayMarkup = Number(daysMarkup.toFixed(3)) <= 1 ? 'jour' : 'jours';
  const wordDayMarkup2 = Number(daysMarkup2.toFixed(3)) <= 1 ? 'jour' : 'jours';
  const wordDayAstreinte = Number(daysAstreinte.toFixed(3)) <= 1 ? 'jour' : 'jours';
  const onSubmitCRA = () => {
    setTimeout(() => {
      setLoadingButtonSubmit(true);
    }, 0);
    void submitCRA().finally(() => {
      setLoadingButtonSubmit(false);
    });
  };

  return (
    <div>
      <ContainerSummary>
        <TitlePart textTitle="Récapitulatif des jours travaillés" />
        <DateSingleLineText text={`${m} ${y}`} />
      </ContainerSummary>
      <DetailsSectionCard
        title="Jour de semaine"
        details="Du lundi au vendredi en heures ouvrées "
        text={`${daysWorked} ${wordDayWeek}`}
      />
      {hasMarkup && (
        <>
          <DetailsSectionCard title="Samedi" details="En heures ouvrées" text={`${daysMarkup} ${wordDayMarkup}`} />
          <DetailsSectionCard title="Dimanche et Férié" text={`${daysMarkup2} ${wordDayMarkup2}`} />
          <DetailsSectionCard title="Heures sup (HNO) ou astreintes" text={`${daysAstreinte} ${wordDayAstreinte}`} />
          <SingleLineText text={`Total: ${total} ${wordDay}`} variant="body2Medium" />
          <LabelAndInput
            name={'confirmAll'}
            textLabel={
              'Je confirme que les jours travaillés hors semaine et les astreintes nécessitent une validation du client.'
            }
            type={'checkbox'}
            checked={checked}
            handleChange={(e) => {
              SetChecked(e.target.checked);
            }}
            id={'confirmAll'}
            readOnly={false}
          />
        </>
      )}
      {total === 0 && <SingleLineText text="Tu n'as pas travaillé ce mois-ci." />}
      <ContainerButton>
        <Button textButton="Valider" onClick={onSubmitCRA} loading={loadingButtonSubmit} disable={!checked} />
        <Button textButton="Modifier la saisie" onClick={() => handleClose()} color="secondary" />
      </ContainerButton>
    </div>
  );
};

export default SummaryDaysWorked;
export { SummaryDaysWorked };

const ContainerSummary = styled('div')(() => ({
  textAlign: 'left',
}));

const ContainerButton = styled('div')(({ theme }) => ({
  margin: '2em auto',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
}));

const DateSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: theme.palette.grey[500],
}));
