import { JobTalent } from '@bloomays-lib/types.shared';
import { months } from './CRA';
import { TFunction } from 'react-i18next';

export const getSentenceJob = (job: JobTalent, t: TFunction<'talentTool'[], undefined>) => {
  let sentence = '-';
  if (job?.jobTitle && job.jobTitle.length > 0) {
    sentence = `${job.jobTitle[0].label} ${t('at')} ${job?.name} ${
      job?.period
        ? `${t('since')} ${months[new Date(job?.period?.startDate).getMonth()].name} ${new Date(
            job?.period?.startDate,
          ).getFullYear()}`
        : ''
    }`;
  }
  return sentence;
};
