import React from 'react';
import { notify } from '../../helpers/toastify';
import MissionDetailCard from '../molecules/MissionDetailCard';
import { LoaderSkeleton, TitlePart } from '@bloomays-lib/ui.shared';
import {
  BrowserBloomer,
  IContact,
  IMissionCardDetails,
  ISociety,
  IBloomerCompletion,
} from '@bloomays-lib/types.shared';
import { ApolloError } from '@apollo/client';
import Popover from '@mui/material/Popover';
import { Button } from '@bloomays-lib/ui.shared';
import ModalMissionComplete from '../molecules/ModalMissionComplete';
import { styled } from '@mui/material/styles';
import { errorLogger } from '../../helpers/error';
import Stack from '@mui/material/Stack';
import MissionCard from '../molecules/MissionCard';

export type ContextMissionDetailsProps = {
  loading: boolean;
  error?: ApolloError;
  contextBloomer?: BrowserBloomer;
  contextMission?: IMissionCardDetails;
  contextContactBloomer?: IContact;
  contextContactBillingClient?: IContact | IContact[];
  contextSocietyBloomer?: ISociety;
  contextSocietyClient?: ISociety;
  contextSocietyClientBilling?: ISociety;
  contextMissionCompletion?: IBloomerCompletion;
  contextContactOperationnal?: IContact;
};

const ContextMissionDetails = ({
  loading,
  error,
  contextBloomer,
  contextMission,
  contextMissionCompletion,
  contextContactBloomer,
  contextContactBillingClient,
  contextSocietyBloomer,
  contextSocietyClient,
  contextSocietyClientBilling,
  contextContactOperationnal,
}: ContextMissionDetailsProps) => {
  const [completeModal, setCompleteModal] = React.useState<{
    id?: string;
    content?: any;
    recordId?: string;
  } | null>(null);

  const handleClick = (event?: { currentTarget: string }, content?: any, recordId?: string) => {
    setCompleteModal({ id: event?.currentTarget, content, recordId });
  };

  const handleClose = () => {
    setCompleteModal(null);
  };

  const getButtonColor = (rate?: number) => {
    if (rate === 100) {
      return 'green';
    }
    if (rate && rate > 70) {
      return 'orange';
    }
    return 'red';
  };

  const open = Boolean(completeModal?.id);
  const id = open ? 'simple-popover' : undefined;

  if (loading || !contextMission || !contextMission.recordId) {
    // les components inférieurs doivent déjà recevoir la data
    return <LoaderSkeleton height={1000} width={900} />;
  }

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve data about bloomers and their infos !',
    });
    notify('error', 'Impossible de récupérer la liste des bloomers. Erreur du server, essaie plus tard ! 😓', error);
  }

  return (
    <ContainerSuccess>
      <TitlePart textTitle="Récapitulatif de la mission" />
      <Stack direction="row" spacing={2} justifyContent="center">
        {contextMissionCompletion ? (
          <Button
            id={`btnComplete${contextMission?.recordId}`}
            style={{
              backgroundColor: getButtonColor(contextMissionCompletion?.complete),
            }}
            onClick={(event: { currentTarget: string }) =>
              handleClick(event, contextMissionCompletion, contextMission?.recordId)
            }
            textButton={`Rempli à ${contextMissionCompletion?.complete} %`}
          ></Button>
        ) : (
          <LoaderSkeleton height={40} width={200} label="" />
        )}
        {contextMission?.replacement && (
          <Button
            id={`btnReplacement${contextMission?.recordId}`}
            style={{
              backgroundColor: 'blue',
              marginLeft: '20px',
            }}
            textButton={`Remplacement`}
          ></Button>
        )}
      </Stack>

      <ContainerDataCardList direction="row" useFlexGap flexWrap="wrap" justifyContent="space-around">
        <MissionDetailCard type="bloomer" data={{ bloomer: contextBloomer }} />
        <MissionCard mission={contextMission} />
        <MissionDetailCard type="society" data={{ society: contextSocietyBloomer, extraInfoTitle: 'du bloomer' }} />
        <MissionDetailCard
          type="contact"
          data={{ contact: contextContactBloomer, extraInfoTitle: 'bloomer de facturation' }}
        />
        <MissionDetailCard type="society" data={{ society: contextSocietyClient, extraInfoTitle: 'du client' }} />
        <MissionDetailCard
          type="society"
          data={{ society: contextSocietyClientBilling, extraInfoTitle: 'de facturation du client' }}
        />
        <MissionDetailCard
          type="contact"
          data={{ contact: contextContactBillingClient, extraInfoTitle: 'client de facturation' }}
        />
        <MissionDetailCard
          type="contact"
          data={{ contact: contextContactOperationnal, extraInfoTitle: 'client opérationnel' }}
        />
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={completeModal?.id as unknown as Element}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {completeModal?.content && completeModal?.recordId && (
            <ModalMissionComplete
              recordId={completeModal?.recordId}
              data={completeModal?.content}
            ></ModalMissionComplete>
          )}
        </Popover>
      </ContainerDataCardList>
    </ContainerSuccess>
  );
};

export default ContextMissionDetails;

const ContainerSuccess = styled('div')(() => ({
  backgroundColor: 'rgb(255, 255, 255)',
  width: '100%',
  margin: 'auto',
}));

const ContainerDataCardList = styled(Stack)(() => ({}));
