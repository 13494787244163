import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import ToastContainer from '../components/molecules/ToastContainer';

import './app.css';
import WithErrorBoundary from '../components/organisms/ErrorBoundary';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { getRouteConfig } from '../helpers/routes';
import { Header, LoaderGlobal } from '@bloomays-lib/ui.shared';
import { setUser } from '@bloomays-lib/common.monitoring/browser';
import fr from '@bloomays-lib/utils.shared';

LicenseInfo.setLicenseKey(
  '9ec9556ca4d445f2052761ea514b020cTz03ODQxMSxFPTE3MzExODMxMzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      fr,
    },
    lng: 'fr', // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false,
    },
  });

export function Fallback() {
  return (
    <>
      <Header />
      <LoaderGlobal />
    </>
  );
}

const App = () => {
  const auth = useAuth();
  if (!auth) {
    return (
      <>
        <Header />
        <LoaderGlobal />
      </>
    );
  }
  const { user, isLoading } = auth;

  if (isLoading) {
    return (
      <>
        <Header />
        <LoaderGlobal />
      </>
    );
  }

  if (user) {
    sessionStorage.setItem('__currentUser', JSON.stringify(user));
    setUser(user);
  }

  return (
    <StyledEngineProvider injectFirst>
      <RouterProvider router={getRouteConfig(auth)} fallbackElement={<Fallback />} />
      <BrowserRouter>
        <ToastContainer></ToastContainer>
      </BrowserRouter>
    </StyledEngineProvider>
  );
};

export default WithErrorBoundary(App);
