import { useState } from 'react';
import { IJobTitle, Location, ITalent, BrowserUploadFile } from '@bloomays-lib/types.shared';
import { Tabs, TabPanel } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { CardItem } from '@bloomays-lib/ui.shared';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Logo } from '@bloomays-lib/ui.shared';
import { ClipboardCopy } from '@bloomays-lib/ui.shared';
import redirect from '../../graphic-charter/icons/redirect.svg';
import SyncTalentQualification from '../molecules/SyncTalentQualification';
import Modal from '../atoms/Modal';
import TalentSheetSideBar from './TalentSheetSideBar';
import TalentSheetExperience from './TalentSheetExperience';
import TalentSheetInResumed from './TalentSheetInResumed';
import lk from '../../graphic-charter/icons/LK.svg';
import debounce from 'lodash/debounce';
import {
  FIND_TALENT,
  GET_ADDRESSES,
  LIST_JOBS,
  UPSERT_CV,
  UPSERT_TALENT_BASIC,
} from '@bloomays-lib/adapter.api-talent';
import React from 'react';
import { cancel, notify } from '../../helpers/toastify';
import TalentSheetActivityLog from './TalentSheetActivityLog';
import TalentSheetCV from './TalentSheetCV';
import TalentSheetFormation from './TalentSheetFormation';
import { jobTitleTalent } from '../../services/serviceTalent';

export type TalentSheetProps = {
  id: string;
  origin: 'searchPage' | 'talentPage';
  handleOpenSyncTalent: () => void;
  openSyncTalent: boolean;
  handleCloseSyncTalent: () => void;
};

const TalentSheet = ({ id, handleOpenSyncTalent, openSyncTalent, handleCloseSyncTalent, origin }: TalentSheetProps) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [modalParam, setModalParam] = useState(searchParams.get('upsertQualif'));
  const [tabsValue, setTabsValue] = useState(2);
  const { t } = useTranslation(['talentSheet', 'random']);
  const [talent, setTalent] = useState<ITalent>();
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);

  const [getAdress, { data: dataLocations, loading: loadingLocations }] = useLazyQuery<{ getAdresses: IJobTitle[] }>(
    GET_ADDRESSES,
    {
      context: { clientName: 'api.talents' },
    },
  );

  const [getTalent, { data }] = useLazyQuery<{ findTalent: ITalent }>(FIND_TALENT, {
    variables: {
      id: id,
    },
    context: { clientName: 'api.talents' },
  });

  const { data: dataJobTitle } = useQuery<{
    listJobTitles: IJobTitle[];
  }>(LIST_JOBS, {
    variables: {
      iso: true,
    },
    context: { clientName: 'api.talents' },
  });

  const [upsertBasic, { loading: loadingUpsertBasic }] = useMutation<{
    upsertTalentBasic: ITalent;
  }>(UPSERT_TALENT_BASIC, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
    onCompleted: () => {
      setUpdatedAt(new Date());
    },
  });

  const [upsertCVFile] = useMutation<{
    uploadCVFile: string[];
  }>(UPSERT_CV, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
    onCompleted: () => {
      setUpdatedAt(new Date());
    },
  });
  const [locationsState, setLocationsState] = useState<Location[] | undefined>(dataLocations?.getAdresses);
  const urlFullTalentSheet = `${import.meta.env.VITE_PUBLIC_APP_ENDPOINT}talentsV2/${id}`;
  const CVRef = React.useRef(null);
  React.useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getTalent({
        variables: { id: id },
        context: { clientName: 'api.talents' },
      });
    }
  }, [getTalent, id]);

  React.useEffect(() => {
    if (data?.findTalent) {
      setTalent(data.findTalent);
    }
  }, [data]);

  const uploadCv = async (files: BrowserUploadFile[]) => {
    if (!files[0]) {
      return;
    }
    try {
      notify('info', `${t('updateInProgress')}`, null, {
        hideProgressBar: false,
        autoClose: 30000,
      });

      const upsertCV = await upsertCVFile({
        variables: {
          input: {
            id: id,
            file: files[0],
          },
        },
      });
      cancel();
      upsertCV?.data?.uploadCVFile && upsertCV?.data?.uploadCVFile?.length > 0
        ? notify('success', 'Le CV a bien été chargé ! :)')
        : notify('error', "Le CV n'a bien été chargé ! 😓");
    } catch (e: any) {
      cancel();
      notify('error', `Le CV n'a bien été chargé ! 😓 ${e.message}`);
    }
  };

  const LK = talent?.profiles?.filter((profile) => profile.network?.toLocaleLowerCase() === 'linkedin')?.[0];

  const getLocationFromMaps = async (locationSearched: string) => {
    await getAdress({
      variables: { input: locationSearched.toLocaleLowerCase() },
      context: { clientName: 'api.talents' },
    });
  };

  const getJobsDebounced = debounce(getLocationFromMaps, 500);

  const onSearchLocation = async (e: any) => {
    if (e.target.value.length >= 3) {
      await getJobsDebounced(e.target.value);
    }

    if (e.target.value.length === 0) {
      setLocationsState([]);
    }
  };

  React.useEffect(() => {
    setLocationsState(dataLocations?.getAdresses);
  }, [dataLocations]);

  return (
    <StyledContainer>
      <Modal
        additionnalCSS={{
          overflowY: 'auto',
          width: '65%',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
        open={openSyncTalent || modalParam === 'active'}
        onClose={() => {
          handleCloseSyncTalent();
          setModalParam('unactive');
        }}
      >
        {talent && talent.id && (
          <SyncTalentQualification
            handleClose={() => {
              handleCloseSyncTalent();
              setModalParam('unactive');
            }}
            candidate={{
              lastname: talent.lastname,
              firstname: talent.firstname,
              jobTitle: jobTitleTalent(talent),
              linkedInUrl: LK?.url,
              profilPictureUrl: talent?.image,
              email: talent?.email,
              phoneNumber: talent.phone,
              externalId: talent.id,
            }}
          />
        )}
      </Modal>
      <TalentSheetSideBar
        origin={origin}
        talent={talent}
        loadingBasic={loadingUpsertBasic}
        upsertBasic={upsertBasic}
        jobTitleList={dataJobTitle?.listJobTitles}
        loadingLocation={loadingLocations}
        onSearch={onSearchLocation}
        locationsState={locationsState}
        setLocationsState={setLocationsState}
      />
      <Tabs
        variant="menu"
        titles={[
          t('inResumed', { ns: 'talentSheet' }),
          'CV',
          t('xp', { ns: 'talentSheet' }),
          t('school', { ns: 'talentSheet' }),
          t('history', { ns: 'talentSheet' }),
        ]}
        value={tabsValue}
        setValue={setTabsValue}
      >
        <TabPanel value={tabsValue} index={0}>
          <TalentSheetInResumed talent={talent} loadingUpsert={loadingUpsertBasic} handleChange={upsertBasic} />
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <TalentSheetCV talent={talent} uploadCv={uploadCv} CVRef={CVRef} />
        </TabPanel>
        <TabPanel value={tabsValue} index={2}>
          <TalentSheetExperience talent={talent} />
        </TabPanel>
        <TabPanel value={tabsValue} index={3}>
          <TalentSheetFormation talent={talent} />
        </TabPanel>
        <TabPanel value={tabsValue} index={4}>
          <TalentSheetActivityLog talentId={id} updatedAt={updatedAt} />
        </TabPanel>
      </Tabs>
      <StyledAction>
        {LK?.url && <StyledHoverLogo image={lk} onClick={() => LK?.url && window.open(LK?.url)} alt="Linkedin Url" />}
        <ClipboardCopy copyText={urlFullTalentSheet} />

        {origin === 'searchPage' ? (
          <StyledHoverLogo image={redirect} onClick={() => window.open(urlFullTalentSheet, '_blank')} />
        ) : null}
        <CardItem
          onClick={() => handleOpenSyncTalent()}
          variant={'pink'}
          size={'extraSmall'}
          emoji={<AddShoppingCartIcon />}
        />
      </StyledAction>
    </StyledContainer>
  );
};

export default TalentSheet;
export { TalentSheet };

const StyledHoverLogo = styled(Logo)(
  () => `
  &:hover {
    cursor: pointer;
  }
`,
);

const StyledAction = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 18px;
  position: absolute;
  zIndex: 2;
  right: 0;
  padding-top: 26px;
  padding-right: 24px;
`,
);

const StyledContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  `,
);
