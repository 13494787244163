import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleSelectedItemsBox from './MultipleSelectedItemsBox';
import SwitchItem from '../atoms/SwitchItem';
import SearchBar from '../atoms/SearchBar';
import useFuse from '../../customHooks/useFuse';
import { Logger } from '../../services/serviceLogger';
const logger = Logger('MultipleSelectSearch');

export interface IMultipleSelectItem {
  label: string;
  count?: number;
  level?: number;
  selected?: boolean;
  id: string;
  alternateLabels?: string[];
}

export type MultipleSelectSearchProps = {
  onSwitch: (e: IMultipleSelectItem) => void;
  onClickLevel?: (e: IMultipleSelectItem, level: number) => void;
  items?: IMultipleSelectItem[];
  search?: string;
  setSearch: (search: string) => void;
  onSearch?: (search: string) => void;
  loading?: boolean;
  setSelectedItemsList: React.Dispatch<React.SetStateAction<IMultipleSelectItem[]>>;
  selectedItemsList: IMultipleSelectItem[];
  onSwitchMultiple?: (items: IMultipleSelectItem[], unSelect?: boolean) => void;
  withLevel?: boolean;
};

const MultipleSelectSearch = ({
  items = [],
  onSwitch,
  onClickLevel,
  onSearch,
  loading = false,
  search,
  setSearch,
  selectedItemsList = [],
  setSelectedItemsList,
  onSwitchMultiple,
  withLevel = true,
}: MultipleSelectSearchProps) => {
  const { t } = useTranslation(['talentTool', 'random', 'talentSheet']);
  const [availableItems, setAvailableItems] = useState<IMultipleSelectItem[]>(items);
  const [selectAll, setSelectAll] = useState({
    level: undefined,
    check: false,
  });

  useEffect(() => {
    if (items.length > 0) setAvailableItems(items);
  }, [items]);

  const searchFuse = useFuse<IMultipleSelectItem>(items, {
    keys: ['label', 'alternateLabels'],
    threshold: 0.2,
    shouldSort: false,
    includeScore: true,
  });

  const handleChange = (item: IMultipleSelectItem) => {
    onSwitch(item);
    if (item.selected) {
      setSelectedItemsList(selectedItemsList.filter((selected) => selected.label !== item.label));
    } else {
      setSelectedItemsList([...selectedItemsList, item]);
    }
  };

  const handleChangeMultiple = (items: IMultipleSelectItem[], isSelect: boolean) => {
    onSwitchMultiple && onSwitchMultiple(items, isSelect);

    if (isSelect) {
      setSelectedItemsList(items);
    } else {
      setSelectedItemsList([]);
      setSearch('');
    }
  };
  const inputReference = useRef<HTMLInputElement>(null);

  if (!onSearch) {
    onSearch = (searchValue: string) => {
      if (searchValue === '') {
        return setAvailableItems(items);
      }

      const res = searchFuse(searchValue);
      const fuseRes = res.map((r) => r.item);
      setAvailableItems(fuseRes);
    };
  }

  return (
    <>
      {onSearch && (
        <SearchBar
          placeholder={t('search')}
          searchValue={search}
          onChange={(e: any) => {
            onSearch?.(e.target?.value || '');
            setSearch(e.target?.value || '');
          }}
          ref={inputReference}
        />
      )}
      <MultipleSelectedItemsBox items={selectedItemsList} handleChange={handleChange} />

      {onSwitchMultiple && items.length > 0 && items.length < 20 && (
        <SwitchItem
          checked={selectAll.check}
          label={selectAll.check ? t('unselectAll', { ns: 'talentSheet' }) : t('selectAll', { ns: 'talentSheet' })}
          onSwitch={() => {
            const isSelect = selectAll.check;
            setSelectAll({
              level: isSelect === true ? undefined : selectAll.level,
              check: !isSelect,
            });
            handleChangeMultiple(items, !isSelect);
          }}
        />
      )}
      {loading && t('loadingText', { ns: 'random' })}
      {availableItems &&
        availableItems.length > 0 &&
        availableItems.map((item: any) => (
          <SwitchItem
            key={item?.label}
            checked={item?.selected}
            withLevel={withLevel}
            count={item.count}
            onClickLevel={
              onClickLevel
                ? (level) => {
                    setSelectedItemsList([
                      ...selectedItemsList.filter((el) => el.label !== item.label),
                      { ...item, level: level },
                    ]);
                    onClickLevel(item, level);
                  }
                : undefined
            }
            label={item?.label}
            onSwitch={() => {
              handleChange(item);
            }}
            levelSelected={item?.level}
          />
        ))}
    </>
  );
};

export default MultipleSelectSearch;
export { MultipleSelectSearch };
