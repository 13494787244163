import { GraphQLError, GraphQLErrorExtensions } from 'graphql';
import { Logger } from '../services/serviceLogger';
import { ApolloError, ServerError, ServerParseError } from '@apollo/client';

export const getAppoloErrorException = (error: ApolloError): (Error & any) | undefined => {
  if (error.networkError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return error.networkError.result?.errors[0]?.extensions?.exception as Error;
  }
  if (error.graphQLErrors) {
    return error.graphQLErrors[0].extensions?.exception as Error;
  }
};

export const getAppoloErrorExtensions = (error: ApolloError): GraphQLErrorExtensions | undefined => {
  if (error.networkError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return error.networkError.result?.errors[0]?.extensions;
  }
  if (error.graphQLErrors) {
    return error.graphQLErrors[0].extensions;
  }
};

export type ApolloErrorTypes = GraphQLError | ApolloServerErrorTypes;
export type ApolloServerErrorTypes = Error | ServerParseError | ServerError;

export const isGraphQLError = (error: ApolloErrorTypes): error is GraphQLError => {
  return (error as GraphQLError).extensions !== undefined;
};

export const isServerError = (error: ApolloErrorTypes): error is ServerError => {
  return (error as ServerError).result !== undefined;
};

export const getErrorStatusCode = (err: any): number => {
  return (
    err.statusCode ||
    err.status ||
    err?.extensions?.status ||
    err?.extensions?.exception?.status ||
    err?.networkError?.statusCode ||
    500
  );
};

export const getApolloErrorStatusCode = (apolloErr: ApolloError): number => {
  if (apolloErr.networkError) {
    return getErrorStatusCode(apolloErr.networkError);
  }
  if (apolloErr.graphQLErrors) {
    return getErrorStatusCode(apolloErr.graphQLErrors[0]);
  }
  return 500;
};

export const errorLogger = (
  error: Error,
  extra?: {
    state?: Record<string, unknown> | unknown;
    extraInfos: string;
    moduleName?: string;
  },
): void => {
  if (!error) return;
  const logger = Logger(extra?.moduleName);
  if (error instanceof ApolloError) {
    const { networkError, graphQLErrors } = error;
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError, index) => {
        if (getErrorStatusCode(graphQLError) >= 500) {
          logger.error(
            `[GraphQL error #${index}] : Message: ${graphQLError.message}`,
            {
              ...extra,
              locations: graphQLError.locations,
              path: graphQLError.path,
              extensions: graphQLError.extensions,
            },
            error,
          );
        } else {
          logger.warn(
            `Message: ${graphQLError.message}`,
            {
              ...extra,
              locations: graphQLError.locations,
              path: graphQLError.path,
              extensions: graphQLError.extensions,
            },
            error,
          );
        }
      });
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`, { ...extra, networkError }, error);
    }
    return;
  }

  logger.error(`[Unknown error]: , ${extra?.extraInfos || ''}`, extra, error);
};

export const getNetworkErrorCode = (networkError: Error | ServerError | ServerParseError) => {
  if (isServerError(networkError)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return networkError.result?.errors[0]?.extensions?.code;
  }
};

export const getGQLErrorCode = (error: GraphQLError): string | undefined => {
  const errorHTTPCode = error.extensions?.code as string;
  if (errorHTTPCode) {
    return errorHTTPCode as string;
  }
  return undefined;
};

export const getApolloErrorCode = (error: ApolloError | undefined): string | undefined => {
  if (!error) return;
  if (error.networkError) {
    return getNetworkErrorCode(error.networkError);
  }
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return getGQLErrorCode(error.graphQLErrors[0]);
  }
};
