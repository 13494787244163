import React, { ForwardedRef, useImperativeHandle, useRef, useState } from 'react';
import { notify } from '../../helpers/toastify';
import { styled } from '@mui/material/styles';
import { BrowserUploadFile } from '@bloomays-lib/types.shared';
import { Modal } from '../atoms/Modal';
import { Button, SingleLineText } from '@bloomays-lib/ui.shared';
import { Dropzone } from './Dropzone';

import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import { Logger } from '../../services/serviceLogger';
import { FileRejection } from 'react-dropzone';
const logger = Logger('Upload component');

export type UploadProps = {
  textLabel: string;
  handleChange: (acceptedFiles: BrowserUploadFile[], rejectedFiles: FileRejection[]) => void;
  id?: string;
  isLoading?: boolean;
  placeholder: string;
  maxLength: number;
  maxFiles?: number;
  fileTypes?: string[];
  preloadedFiles: BrowserUploadFile[];
  autoload?: boolean;
  displayIcons?: boolean;
  name?: string;
  titleModal?: string;
  subTitleModal?: string;
  required?: boolean;
  noDrop?: boolean;
  noFeedback?: boolean;
  noModal?: boolean;
};

export const Upload = React.forwardRef(
  (
    {
      handleChange,
      id,
      textLabel,
      isLoading = false,
      placeholder,
      maxLength = 500000,
      maxFiles = 1,
      fileTypes,
      preloadedFiles = [],
      autoload = true,
      displayIcons,
      titleModal,
      subTitleModal,
      required,
      noDrop: noDrag = false,
      noFeedback = false,
      noModal = false,
      ...props
    }: UploadProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [open, setOpen] = useState<boolean>(false);
    const [missRequirement, setMissRequirement] = useState<boolean>(false);
    const [files, setFiles] = useState<BrowserUploadFile[] | []>(preloadedFiles || []);
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[] | []>([]);
    const inputRef = useRef(null);
    const dropZone = useRef<{ open: () => void }>({ open: () => logger.info('Dropzone ref is not ready') });

    React.useEffect(() => {
      setFiles(preloadedFiles);
    }, [preloadedFiles]);

    React.useEffect(() => {
      setMissRequirement(false);
    }, [required]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => ({
      scrollIntoView(opt: any) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputRef.current?.scrollIntoView(opt);
        } catch (err: any) {
          logger.warn('Error occured while trying to scrollIntoView on component', err);
        }
      },
      validate() {
        if (required) {
          if (acceptedFileItems.length > 0 && rejectedFileItems.length === 0) {
            setMissRequirement(false);
            return true;
          }
          setMissRequirement(true);
          return false;
        }
        setMissRequirement(false);
        return rejectedFileItems.length === 0;
      },
    }));

    const onUpload = (id: string, files: File[], rejected: FileRejection[]) => {
      if (files?.[0]?.size === 0) {
        notify('error', 'Fichier vide ! 😓', null, {
          hideProgressBar: false,
          autoClose: 10000,
        });
        return;
      }
      setOpen(false);
      setFiles(files);
      setRejectedFiles(rejected);
      if (required && files.length > 0) {
        setMissRequirement(false);
      }
      handleChange(files, rejected);
    };

    const handleOpen = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (noDrag) {
        return dropZone.current.open();
      }
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const acceptedFileItems = files.map((file, i) => (
      <StyledContainerValid key={`${file.url}-${i}`}>
        <PathValueSingleLineText text={file.path} />
        {displayIcons && (
          <StyledContainerIcon>
            {file.url && (
              <ListIcon
                onClick={() => {
                  window?.open(file.url, '_blank')?.focus();
                }}
                size="large"
              >
                <Visibility titleAccess="Voir" />
              </ListIcon>
            )}
            <ListValid disabled={true} size="large">
              <CheckValid titleAccess="Valide" htmlColor={'green'} />
            </ListValid>
          </StyledContainerIcon>
        )}
      </StyledContainerValid>
    ));

    const getDropZoneComponent = () => {
      return (
        <>
          {(titleModal || subTitleModal) && (
            <StyledTitle>
              {titleModal && <SingleLineText text={titleModal} variant={'subtitle2Medium'} />}
              {subTitleModal && <SingleLineText text={subTitleModal} variant={'subtitle2Light'} />}
            </StyledTitle>
          )}
          <Dropzone
            id={id || ''}
            title="Selectionner"
            placeholder={placeholder}
            maxFiles={maxFiles || 1}
            maxLength={maxLength || 20000000}
            isLoading={isLoading || false}
            autoload={autoload || false}
            onUpload={onUpload}
            fileTypes={fileTypes || []}
            ref={dropZone}
            {...props}
          ></Dropzone>
        </>
      );
    };

    const rejectedFileItems = rejectedFiles?.map((rejectedItem) => (
      <UploadCard key={rejectedItem.file.name}>
        <ListFile>
          <ErrorTextSingleLineText text={rejectedItem.errors?.[0]?.message || 'Erreur inconnue !'} />
          <ListValid disabled={true} size="large">
            <ErrorValid titleAccess={rejectedItem?.errors?.[0].message} color={'error'} />
          </ListValid>
        </ListFile>
      </UploadCard>
    ));
    let buttonTitle = textLabel;
    if (required) {
      buttonTitle += ' *';
    }

    if (noModal) {
      return <div ref={inputRef}>{getDropZoneComponent()}</div>;
    }

    return (
      <div ref={inputRef}>
        <StyledContainer>
          <StyledButton textButton={buttonTitle} title={buttonTitle} onClick={handleOpen} {...props} />
          {!noFeedback && (files.length > 0 || rejectedFiles.length > 0) && (
            <div style={{ display: 'flex', flexFlow: 'column wrap' }}>
              {acceptedFileItems}
              {rejectedFileItems}
            </div>
          )}
          {missRequirement && <ErrorValid titleAccess={'Champs requis'} color={'error'} />}
        </StyledContainer>
        {noDrag ? (
          <HiddenContainer>{getDropZoneComponent()}</HiddenContainer>
        ) : (
          <Modal
            open={open}
            onClose={handleClose}
            noClose={true}
            additionnalCSS={{
              width: '533px',
              padding: '32px 32px 32px 32px',
              border: 'none',
              borderRadius: '20px',
            }}
          >
            {getDropZoneComponent()}
          </Modal>
        )}
      </div>
    );
  },
);

Upload.displayName = 'Upload';

const StyledButton = styled(Button)(
  ({ theme }) => `
  background-color: ${theme.palette.secondary[600]};
  color: ${theme.palette.secondary.light};
  margin: 0.8em 0;
  `,
);

const StyledContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  `,
);

const StyledContainerValid = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  `,
);

const StyledTitle = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-bottom: 16px;
  `,
);

const StyledContainerIcon = styled('div')(
  () => `
  display: flex;
  margin-left: 0.4em;
  `,
);

const HiddenContainer = styled('div')(
  () => `
  display: none;
  `,
);

const UploadCard = styled('div')(() => ({
  width: '100%',
  margin: '0.2em auto 0 0',
}));

const PathValueSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: theme.palette.secondary.light,
}));

const ErrorTextSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: theme.palette.tertiary.red,
}));

const ListFile = styled('li')(({ theme }) => ({
  flexFlow: 'row wrap',
  display: 'flex',
  alignItems: 'center',
}));

const ListIcon = styled(IconButton)(({ theme }) => ({
  paddingRight: '10px',
  paddingLeft: '0px',
}));

const ListValid = styled(IconButton)(({ theme }) => ({
  paddingLeft: '0px',
}));

const CheckValid = styled(CheckCircleIcon)(({ theme }) => ({
  paddingLeft: '0px',
}));

const ErrorValid = styled(ErrorIcon)(({ theme }) => ({
  paddingLeft: '0px',
}));
