import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from '../../helpers/toastify';
import { useMutation, useQuery } from '@apollo/client';

import { GET_ONE_ACTIVITY, ACTIVITY_UPSERT, ACTIVITIES_FOR_ONE_MISSION } from '@bloomays-lib/adapter.api-bloomer';

import AddCRA from '../organisms/AddCRA';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { AirtableActivity, EachDay } from '@bloomays-lib/types.shared';
import { calculPerDay, convertActivityToEachDaysArray, createDaysDetail, months } from '../../helpers/CRA';
import { errorLogger, getApolloErrorCode } from '../../helpers/error';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';

const FormCRA = () => {
  const { missionRecordId, date } = useParams<{
    missionRecordId: string;
    date: string;
  }>();

  if (!date) {
    throw new Error('Missing date params');
  }

  const [upsertActivity] = useMutation<{ upsertActivity: AirtableActivity }>(ACTIVITY_UPSERT, {
    refetchQueries: [
      {
        query: GET_ONE_ACTIVITY,
        variables: { month: date, missionRecordId: missionRecordId },
      },
      {
        query: ACTIVITIES_FOR_ONE_MISSION,
        variables: { recordId: missionRecordId },
      },
    ],
  });
  const [, setResponsePostCRA] = useState('');
  const [valueOfAll, setValueOfAll] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showApplyValue, setShowApplyValue] = useState(false);
  const [activity, setActivity] = useState<AirtableActivity | undefined>();
  const [eachDay, setEachDay] = useState<EachDay[]>([]);
  const navigate = useNavigate();

  const { data, error, loading } = useQuery<{ activities: AirtableActivity[] }>(GET_ONE_ACTIVITY, {
    variables: {
      month: date,
      missionRecordId: missionRecordId,
    },
  });

  const submitCRA = async (items: EachDay[]): Promise<void> => {
    let result;
    const details = createDaysDetail(items);
    const day = calculPerDay(items, 'nbDaysWorked');
    const dayAstreinte = calculPerDay(items, 'astreinte');
    const dayMarkup = calculPerDay(items, 'nbDaysMarkupWorked');
    const dayMarkup2 = calculPerDay(items, 'nbDaysMarkup2Worked');

    if (!missionRecordId) {
      notify(
        'info',
        "Il semblerait qu'il manque des données sur la mission, peux-tu re sélectionner la mission s'il te plaît ?",
      );
      return;
    }
    setResponsePostCRA('loading');
    try {
      const inputVar: any = {
        month: activity?.month,
        nbDaysWorked: day,
        nbDaysMarkupWorked: dayMarkup,
        nbDaysMarkup2Worked: dayMarkup2,
        missionRecordId: missionRecordId,
        nbDaysDetails: JSON.stringify(details),
        astreinte: dayAstreinte,
        signedCount: 0,
      };
      if (activity?.recordId) {
        inputVar.recordId = activity.recordId;
      }
      result = await upsertActivity({
        variables: {
          input: inputVar,
        },
      });
      if (result.data) {
        const month = new Date(result.data.upsertActivity.month);
        setResponsePostCRA('posted');
        notify(
          'success',
          `Le CRA pour le mois de ${months[month.getMonth()].name}
              a bien été pris en compte! :)`,
        );
      }
      navigate(`/mission/${missionRecordId}`);
    } catch (error: any) {
      setResponsePostCRA('not posted');
      errorLogger(error, {
        extraInfos: 'Error adding / edit CRA',
        state: activity,
      });
      notify('error', "Souci avec l'ajout du CRA, reviens plus tard 😓 ", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!data?.activities?.[0]) {
      return;
    }
    setActivity(data?.activities?.[0]);
  }, [data]);

  useEffect(() => {
    if (!activity) {
      return;
    }
    const result = convertActivityToEachDaysArray(activity);
    setEachDay(result);
  }, [activity]);

  useEffect(() => {
    if (!error) {
      return;
    }
    let textError;
    const errorCode = getApolloErrorCode(error);
    switch (errorCode) {
      case 'UNKNOWN_RECORD_ID':
        textError = "Cette mission n'existe pas";
        break;
      case 'OBJECT_NOT_OWNED':
        textError = "L'id de cette mission ne semble pas vous appartenir";
        break;
      default:
        textError = 'une erreur non identifiée est survenue';
    }
    notify('error', 'Impossible de récupérer tes données. Erreur du server, essaie plus tard ! 😓 ', textError);
  }, [error]);

  if (loading) {
    return <LoaderSkeleton height={500} width={1200} />;
  }

  return (
    <div>
      <AddCRA
        month={activity?.month || date}
        eachDay={eachDay}
        valueOfAll={valueOfAll}
        setValueOfAll={setValueOfAll}
        isSelectAll={isSelectAll}
        setIsSelectAll={setIsSelectAll}
        showApplyValue={showApplyValue}
        setShowApplyValue={setShowApplyValue}
        submitCRA={submitCRA}
      />
    </div>
  );
};

export default WithErrorBoundary(FormCRA);
