import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { notify } from '../helpers/toastify';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { IJobTitle } from '@bloomays-lib/types.shared';
import {
  UPDATE_JOBTITLE,
  DELETE_JOBTITLE,
  JOBTITLES,
  MERGE_JOBTITLE_BY_ID,
  CREATE_JOBTITLE,
} from '@bloomays-lib/adapter.api-talent';
import { DELETE_UNUSED_JOBTITLE } from '@bloomays-lib/adapter.api-talent';
import { LabelManagmentProps } from '../components/organisms/LabelManagment';
import { errorLogger } from '../helpers/error';

const ServiceJobTitles = (WrappedComponent: React.ComponentType<any>) =>
  function Component() {
    const {
      loading: loadingJTs,
      error: errorJTs,
      data: dataJTs,
    } = useQuery<{ listJobTitles: IJobTitle[] }>(JOBTITLES, {
      context: { clientName: 'api.talents' },
    });

    const [mergeJTsById] = useMutation<{
      mergeJobTitlesById: IJobTitle;
    }>(MERGE_JOBTITLE_BY_ID, { context: { clientName: 'api.talents' } });

    const [updateJT] = useMutation<{
      updateJobTitle: IJobTitle;
    }>(UPDATE_JOBTITLE, { context: { clientName: 'api.talents' } });

    const [createJT] = useMutation<{
      createJobTitle: IJobTitle;
    }>(CREATE_JOBTITLE, { context: { clientName: 'api.talents' } });

    const [deleteJT] = useMutation<{
      deleteJobTitle: IJobTitle;
    }>(DELETE_JOBTITLE, { context: { clientName: 'api.talents' } });

    const [deleteUnusedJobTitle] = useMutation<{
      deleteUnusedJobTitle: number;
    }>(DELETE_UNUSED_JOBTITLE, {
      context: { clientName: 'api.talents' },
    });

    function notifyError(error: any) {
      errorLogger(error, {
        extraInfos: 'Internal server error, impossible to relaunch jobTitle !',
      });
      notify('error', error.message, error);
    }

    async function onUpdate(newJobTitle: IJobTitle, oldJobTitle: IJobTitle) {
      // mergeJobTitlesById()
      return (
        await updateJT({
          variables: {
            jobTitle: newJobTitle,
          },
        })
      ).data?.updateJobTitle;
    }

    async function onCreate(newJobTitle: IJobTitle) {
      return (
        await createJT({
          variables: {
            jobTitle: newJobTitle,
          },
        })
      ).data?.createJobTitle;
    }

    async function onDelete(jobTitleId: string) {
      // mergeJobTitlesById()
      return (
        await deleteJT({
          variables: {
            jobTitleId: jobTitleId,
          },
        })
      ).data?.deleteJobTitle;
    }

    async function onMerge(mergeToJobTitleId: string, mergeInJobTitleId: string) {
      // mergeJobTitlesById()
      return (
        await mergeJTsById({
          variables: {
            dryRun: false,
            mergeInJobTitleId: mergeInJobTitleId,
            mergeToJobTitleId: mergeToJobTitleId,
          },
        })
      ).data?.mergeJobTitlesById;
    }

    async function onDeleteUnusedJobTitles() {
      const res = await deleteUnusedJobTitle();
      return res.data?.deleteUnusedJobTitle || 0;
    }

    useEffect(() => {
      if (!errorJTs) return;
      notifyError(errorJTs);
    }, [errorJTs]);

    if (loadingJTs) {
      return <LoaderSkeleton height={600} width={800} />;
    }

    const jobTitles = dataJTs?.listJobTitles ?? [];

    const injectedProps: LabelManagmentProps<IJobTitle> = {
      labels: jobTitles,
      onError: notifyError,
      onUpdate: onUpdate,
      onDelete: onDelete,
      onMerge: onMerge,
      onCreate: onCreate,
      onDeleteUnusedLabels: onDeleteUnusedJobTitles,
    };

    return <WrappedComponent {...injectedProps} />;
  };

export default ServiceJobTitles;
export { ServiceJobTitles };
