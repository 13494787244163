import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

const StyledSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.paper.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.dark,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette.grey[900],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export type SwitchProps = {
  label?: string;
  onSwitch: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  id?: string;
};

const FormControlStyled = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    paddingLeft: '8px',
    color: theme.palette.grey[100],
    fontSize: '14px',
  },
  '&.MuiFormControlLabel-labelPlacementEnd': {
    marginLeft: '0px',
  },
}));

const Switch = ({ label, onSwitch, checked, id }: SwitchProps) => {
  return (
    <FormControlStyled
      control={<StyledSwitch id={id} onChange={onSwitch} sx={{ m: 1 }} checked={checked} />}
      label={label}
    />
  );
};

export default Switch;
export { Switch };
