/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { notify } from '../../helpers/toastify';

import {
  CANCEL_INVITE,
  UPLOAD_ACTIVITY_FILE,
  SEND_EMBED_INVITE,
  ACTIVITIES_FOR_ONE_MISSION,
  UPSERT_NPS_FOLLOWUP,
  GET_MISSION_FOLLOWUP,
  DELETE_ACTIVITY_SUPPLIER_INVOICE,
} from '@bloomays-lib/adapter.api-bloomer';

import WithErrorBoundary from '../organisms/ErrorBoundary';
import { AirtableActivity, IActivity, IMission } from '@bloomays-lib/types.shared';
import { months } from '../../helpers/CRA';
import { errorLogger, getApolloErrorStatusCode, getAppoloErrorExtensions } from '../../helpers/error';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { FileActivityObject, _ActivityModified } from '../../types/components';
import TableManagementCRA from '../organisms/TableManagementCRA';
import { trim } from 'lodash/fp';

const CRAManagement = () => {
  const [modalUploadCRA, setModalUploadCRA] = useState<{
    show: boolean;
    id?: null | string;
  }>({
    show: false,
    id: null,
  });
  const [modalUploadBill, setModalUploadBill] = useState<{
    show: boolean;
    id?: string | null;
    urlInvoiceS3?: string;
    urlAdditionalInvoicesS3?: string;
  }>({
    show: false,
    id: null,
  });
  const { missionRecordId } = useParams<{ missionRecordId: string }>();
  const [cancelInviteBloomerCRA] = useMutation(CANCEL_INVITE);
  const [getEmbedInviteBloomerCRA] = useMutation(SEND_EMBED_INVITE);
  const [uploadActityFile] = useMutation(UPLOAD_ACTIVITY_FILE);
  const { error, data, loading, refetch } = useQuery<{ missions: IMission[] }>(ACTIVITIES_FOR_ONE_MISSION, {
    variables: { recordId: missionRecordId },
  });
  const [bloomerFollowUp] = useMutation(UPSERT_NPS_FOLLOWUP);
  const [getNPS] = useLazyQuery(GET_MISSION_FOLLOWUP, {
    fetchPolicy: 'network-only',
  });

  const [deleteInvoice] = useMutation<{ deleteActivitySupplierInvoice: AirtableActivity }>(
    DELETE_ACTIVITY_SUPPLIER_INVOICE,
    {
      refetchQueries: [
        {
          query: ACTIVITIES_FOR_ONE_MISSION,
          variables: { recordId: missionRecordId },
        },
      ],
    },
  );

  const missionLoaded = data && data.missions;
  const [displayNewActivity, setDisplayNewActivity] = useState(false);
  const [newActivityValue, setNewActivityValue] = useState<Date | null>(new Date());
  const [arrayOfCRA, setArrayOfCRA] = useState<_ActivityModified[]>([]);
  const [responseSign, setResponseSign] = useState<{
    status: string;
    idRow: string | number | null;
  }>({
    status: '',
    idRow: null,
  });
  const [responseUploadCRA, setResponseUploadCRA] = useState<{
    status: string;
    idRow: string | null;
  }>({
    status: '',
    idRow: null,
  });
  const [CRAUrlEmbed, setCRAUrlEmbed] = useState(null);
  const [stateNPS, setStateNPS] = useState({
    loading: false,
  });

  const upsertFollowUp = async (args: {
    selected?: number;
    comment?: string;
    collectDate: Date;
    activityId: string;
  }) => {
    try {
      setStateNPS({ ...stateNPS, loading: true });
      const followUp = await bloomerFollowUp({
        variables: {
          input: {
            score: args.selected,
            comment: args?.comment,
            collectDate: args.collectDate,
            activityId: args.activityId,
          },
        },
      });
      setStateNPS({ ...stateNPS, loading: false });
      return followUp;
    } catch (e: any) {
      errorLogger(e, {
        extraInfos: 'Nps bloomer follow up failed',
        state: { recordId: missionRecordId },
      });
      setStateNPS({ ...stateNPS, loading: false });
    }
  };

  const dateStrToMonth = (arrData: IMission[]): _ActivityModified[][] => {
    const arrMonthsFilled = arrData.map((el: IMission) => {
      if (!el.activity) {
        return [];
      }
      const activities = el.activity;
      return activities.map((element: IActivity) => {
        const dateToChange = element.month;
        const dateObject = new Date(dateToChange);
        return {
          ...element,
          date: dateObject,
          month: months[dateObject.getMonth()].name,
          monthNumber: months[dateObject.getMonth()].i,
          year: dateObject.getFullYear(),
          nbTotalDaysWorked: Number(
            (
              (element?.nbDaysWorked || 0) +
              (element?.nbDaysMarkupWorked || 0) +
              (element?.nbDaysMarkup2Worked || 0) +
              (element?.astreinte || 0)
            ).toFixed(3),
          ),
          sign: element.signing || false,
          contactOperations: el?.contactOperations?.email,
        };
      });
    });
    return arrMonthsFilled;
  };

  useEffect(() => {
    if (error) {
      notify('error', 'Souci avec le serveur, reviens plus tard 😓 ', error);
    }
  }, [error]);

  useEffect(() => {
    if (missionLoaded) {
      const dataFilled = dateStrToMonth(missionLoaded);
      const sortedDataFilled = dataFilled?.[0]?.sort((a, b) => {
        return +b.date - +a.date;
      });
      setArrayOfCRA(sortedDataFilled);
    }
  }, [missionLoaded]);

  const displayRow = () => {
    setDisplayNewActivity(true);
  };

  const cancelInviteSignCRA = async (idDocument: string, row: string | number | null) => {
    setResponseSign({ ...responseSign, status: 'loading', idRow: row });
    try {
      const cancelInvite = await cancelInviteBloomerCRA({
        variables: {
          input: {
            signingDocumentId: idDocument,
          },
        },
        refetchQueries: [
          {
            query: ACTIVITIES_FOR_ONE_MISSION,
            variables: { recordId: missionRecordId },
          },
        ],
        awaitRefetchQueries: true,
      });
      if (cancelInvite.data) {
        notify('success', 'La demande de signature a bien été annulée !');
      }
      setResponseSign({ ...responseSign, status: '' });
      setCRAUrlEmbed(null);
    } catch (error: any) {
      notify('error', "La demande de signature n'a pas été annulée, essais plus tard ! 😓 ", error);

      setResponseSign({ ...responseSign, status: 'not send' });
      errorLogger(error, {
        extraInfos: 'Impossible to cancel invite sign CRA',
        state: { signingDocumentId: idDocument },
      });
    }
  };

  const getEmbedBloomerCRA = async (activityId: string, to: string) => {
    setResponseSign({ ...responseSign, status: 'loading' });
    try {
      const sendEmbedInvite = await getEmbedInviteBloomerCRA({
        variables: {
          input: {
            activityRecordID: activityId,
            to: trim(to),
          },
        },
        refetchQueries: [
          {
            query: ACTIVITIES_FOR_ONE_MISSION,
            variables: { recordId: missionRecordId },
          },
        ],
      });
      if (sendEmbedInvite) {
        setCRAUrlEmbed(sendEmbedInvite.data.getEmbedInviteBloomerCRA);
        setResponseSign({ ...responseSign, status: 'send' });
      }
    } catch (error) {
      notify(
        'error',
        "Nous sommes désolés? Nous ne pouvons pas effectuer cette opération pour l'instant. Veuillez contacter Sarah.",
        error,
      );
    }
  };

  const mission = missionLoaded?.[0];

  const uploadFile =
    (activityFileType: 'Invoice' | 'CRA', extraErrorInfos: any, onEndHandler?: any) =>
    async (activityRecordId: string, files: FileActivityObject) => {
      setResponseUploadCRA({
        status: 'loading',
        idRow: activityRecordId,
      });
      try {
        const upload = await uploadActityFile({
          variables: {
            input: {
              activityRecordId: activityRecordId,
              file: files,
              activityFileType: activityFileType,
            },
          },
          refetchQueries: [
            {
              query: ACTIVITIES_FOR_ONE_MISSION,
              variables: { recordId: missionRecordId },
            },
          ],
        });
        if (upload.data) {
          setResponseUploadCRA({ ...responseUploadCRA, status: 'send' });
          if (onEndHandler) {
            onEndHandler({
              show: false,
              id: '',
            });
          }
        }
        notify('success', 'Envoyé avec succès ! :)');
        setResponseUploadCRA({ ...responseUploadCRA, status: '' });
      } catch (error: any) {
        setResponseUploadCRA({
          ...responseUploadCRA,
          status: 'not send',
        });
        if (onEndHandler) {
          onEndHandler({
            show: false,
            id: '',
          });
        }
        const statusCode = getApolloErrorStatusCode(error);
        const apolloErrorExt = getAppoloErrorExtensions(error);
        if (apolloErrorExt?.code === 'SOCIETY_NOT_FOUND') {
          notify(
            'warning',
            'Votre société nest pas définie pour cette mission. Saisir les informations',
            undefined,
            undefined,
            `/society/new/mission/${mission?.recordId}`,
          );
        } else {
          if (statusCode >= 500) {
            notify('error', 'Non envoyé. Souci avec le serveur, reviens plus tard 😓', error);
            errorLogger(error, {
              extraInfos: extraErrorInfos,
            });
          } else {
            notify('warning', error.message);
          }
        }
      }
    };

  if (loading || !mission) {
    return <LoaderSkeleton height={500} width={1200} />;
  }

  return (
    <div>
      <TableManagementCRA
        getNPS={getNPS}
        upsertFollowUp={upsertFollowUp}
        setResponseSign={setResponseSign}
        refetchActivities={refetch}
        CRAUrlEmbed={CRAUrlEmbed}
        getEmbedBloomerCRA={getEmbedBloomerCRA}
        setNewActivityValue={setNewActivityValue}
        responseSign={responseSign}
        cancelInviteSignCRA={cancelInviteSignCRA}
        displayRow={displayRow}
        arrayOfCRA={arrayOfCRA}
        displayNewActivity={displayNewActivity}
        newActivityValue={newActivityValue}
        error={error}
        modalUploadCRA={modalUploadCRA}
        responseUploadCRA={responseUploadCRA}
        uploadCRA={uploadFile('CRA', `Impossible d'envoyer un CRA`, setModalUploadCRA)}
        uploadInvoice={uploadFile('Invoice', `Impossible d'envoyer un Invoice`, setModalUploadBill)}
        modalUploadBill={modalUploadBill}
        setModalUploadBill={setModalUploadBill}
        mission={mission}
        stateNPS={stateNPS}
        deleteInvoice={async (activityRecordID) => {
          await deleteInvoice({
            variables: {
              activityRecordID: activityRecordID,
              force: false,
            },
          }).catch((e) => {
            notify('error', 'Impossible de supprimer la facture', e);
          });
        }}
      />
    </div>
  );
};

export default WithErrorBoundary(CRAManagement);
