import { gql } from '@apollo/client';

export const ACTIVITY_UPSERT = gql`
  mutation upsertActivity($input: activityInput!) {
    upsertActivity(input: $input) {
      recordId
      month
      nbDaysWorked
      nbDaysMarkupWorked
      nbDaysMarkup2Worked
      missionRecordId
      astreinte
    }
  }
`;

export const SEND_EMBED_INVITE = gql`
  mutation getEmbedInviteBloomerCRA($input: bloomerCRASendInviteInput!) {
    getEmbedInviteBloomerCRA(input: $input)
  }
`;

export const GET_EMBED_CLIENT_INVITE = gql`
  mutation getEmbedInviteClientCRA($signingId: String!) {
    getEmbedInviteClientCRA(signingId: $signingId)
  }
`;

export const CANCEL_INVITE = gql`
  mutation cancelInviteBloomerCRA($input: bloomerCRACancelInviteInput!) {
    cancelInviteBloomerCRA(input: $input)
  }
`;
export const UPLOAD_ACTIVITY_FILE = gql`
  mutation uploadActivityFile($input: uploadActivityInput!) {
    uploadActivityFile(input: $input)
  }
`;

export const GET_ELECTRONICSIGN_LINK = gql`
  mutation getElectronicSignLink($externalDocumentId: ID!, $signerId: ID!) {
    getElectronicSignLink(externalDocumentId: $externalDocumentId, signerId: $signerId)
  }
`;

export const CREATE_ACTIVITY_CUSTOMER_INVOICE = gql`
  mutation createActivityCustomerInvoice($activityRecordID: ID!, $force: Boolean) {
    createActivityCustomerInvoice(activityRecordID: $activityRecordID, force: $force) {
      id
      processedOn
      progress
    }
  }
`;

export const CREATE_ACTIVITY_CUSTOMER_INVOICE_RETRY = gql`
  mutation createActivityCustomerInvoiceRetry($activityRecordID: ID!, $force: Boolean) {
    createActivityCustomerInvoiceRetry(activityRecordID: $activityRecordID, force: $force) {
      id
      processedOn
      progress
    }
  }
`;

export const CREATE_ACTIVITY_SUPPLIER_INVOICE = gql`
  mutation createActivitySupplierInvoice($activityRecordID: ID!, $force: Boolean, $byPassComplianceCheck: Boolean) {
    createActivitySupplierInvoice(
      activityRecordID: $activityRecordID
      force: $force
      byPassComplianceCheck: $byPassComplianceCheck
    ) {
      id
      processedOn
      progress
    }
  }
`;

export const CREATE_ACTIVITY_SUPPLIER_INVOICE_RETRY = gql`
  mutation createActivitySupplierInvoiceRetry(
    $activityRecordID: ID!
    $force: Boolean
    $byPassComplianceCheck: Boolean
  ) {
    createActivitySupplierInvoiceRetry(
      activityRecordID: $activityRecordID
      force: $force
      byPassComplianceCheck: $byPassComplianceCheck
    ) {
      id
      processedOn
      progress
    }
  }
`;

export const UPSERT_BLOOMER = gql`
  mutation upsertBloomer($input: bloomerInput!) {
    upsertBloomer(input: $input) {
      recordId
      firstname
      lastname
      email
      secondaryEmail
      phoneNumber
      slack
      credentialID
      communicationType
      accountManager {
        recordId
      }
      mission {
        recordId
      }
    }
  }
`;

export const FINISH_INVITATION = gql`
  mutation finishInvitation($token: String!) {
    finishInvitation(token: $token)
  }
`;

export const CLIENT_RELAUNCH_CRA = gql`
  mutation relaunchInviteClientCRA($signingDocumentId: ID!) {
    relaunchInviteClientCRA(signingDocumentId: $signingDocumentId)
  }
`;

export const UPSERT_BILLING = gql`
  mutation upsertBilling($input: BillingInput!) {
    upsertBilling(input: $input) {
      CustomerName
      SalesPriceWithoutTaxes
      TrialPeriodEndDate
      BillingDate
      Description
      nextStep
      recordId
      Billed
      externalId
    }
  }
`;

export const BOOM_MUTATION = gql`
  mutation boomUpdate {
    boomUpdate
  }
`;

export const BLOOMER_RELAUNCH_CRA = gql`
  mutation relaunchBloomerCRA($missionId: ID!, $month: Date) {
    relaunchBloomerCRA(missionId: $missionId, month: $month)
  }
`;

export const UPDATE_PREFERENCES = gql`
  mutation updatePreferences($preferences: PreferencesInput) {
    updatePreferences(preferences: $preferences)
  }
`;

export const UPSERT_TALENT_QUALIF = gql`
  mutation upsertTalentQualification($input: CandidatInput) {
    upsertTalentQualification(input: $input)
  }
`;

export const CREATE_MISSION = gql`
  mutation addMission($input: addMissionInput!) {
    addMission(input: $input) {
      label
      beginMission
      endMission
      statusBloomer
      statusClient
      recordId
    }
  }
`;

export const DELETE_MISSION = gql`
  mutation deleteMission($missionId: String!) {
    deleteMission(missionId: $missionId) {
      message
    }
  }
`;

export const CREATE_PRICING = gql`
  mutation addPricing($input: addPricingInput!) {
    addPricing(input: $input) {
      startDate
      purchasePricePerDayWithoutTaxes
      salePricePerDayWithoutTaxes
      taxes
      recordId
    }
  }
`;

export const STOP_MISSION = gql`
  mutation stopMission($endMission: String!, $recordId: ID!) {
    stopMission(endMission: $endMission, recordId: $recordId)
  }
`;

export const EXTEND_MISSION = gql`
  mutation extensionMission($endMission: String!, $recordId: ID!, $useAmendmentTemplate: Boolean!) {
    extensionMission(endMission: $endMission, recordId: $recordId, useAmendmentTemplate: $useAmendmentTemplate)
  }
`;

export const UPDATE_MISSION = gql`
  mutation updateMission($input: UpdateMissionGQLInput) {
    updateMission(input: $input) {
      recordId
    }
  }
`;

export const UPSERT_NPS_FOLLOWUP = gql`
  mutation missionFollowUp($input: NPSScoringInput) {
    missionFollowUp(input: $input) {
      recordId
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($recordId: ID, $read: Boolean) {
    updateNotification(recordId: $recordId, read: $read) {
      read
    }
  }
`;

export const DELETE_ALL_NOTIFICATION = gql`
  mutation deleteAllNotification {
    deleteAllNotification
  }
`;

export const READ_ALL_NOTIFICATION = gql`
  mutation readAllNotification {
    readAllNotification
  }
`;

export const UPSERT_SOCIETY = gql`
  mutation upsertSociety($input: societyInput!) {
    upsertSociety(input: $input) {
      recordId
      name
      status
      siret
      mission {
        recordId
      }
    }
  }
`;

export const UPSERT_BLOOMER_SOCIETY = gql`
  mutation upsertBloomerSociety($input: bloomerSocietyInput!) {
    upsertBloomerSociety(input: $input) {
      recordId
      name
      status
      siret
      bloomerBillingMission
      IBAN
      BIC
      KBIS
      portage
    }
  }
`;

export const UPSERT_EXTESION_ASSESSMENT = gql`
  mutation upsertExtensionAssessment($input: UpsertExtensionAssessmentGQLInput) {
    upsertExtensionAssessment(input: $input) {
      missionId
      assessment
      newEndDate
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const UPLOAD_AMENDMENT = gql`
  mutation uploadAmendment($input: UploadAmendmentGQLInput!) {
    uploadAmendment(input: $input) {
      recordId
      type
      step
      status
      nbSigner
      signedCount
      emptyFileUrl
      signedFileUrl
      signedDate
      externalDocumentId
    }
  }
`;

export const DELETE_ACTIVITY_SUPPLIER_INVOICE = gql`
  mutation deleteActivitySupplierInvoice($activityRecordID: ID!, $force: Boolean) {
    deleteActivitySupplierInvoice(activityRecordID: $activityRecordID, force: $force) {
      id
    }
  }
`;

export const VALIDATE_CRA = gql`
  mutation validateCRA($activityRecordID: ID!, $validate: Boolean) {
    validateCRA(activityRecordID: $activityRecordID, validate: $validate) {
      recordId
      validated
    }
  }
`;
