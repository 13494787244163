import MaterialTextField from '@mui/material/TextField';
import { SxProps, Theme, styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { AriaRole, useEffect, useMemo, useState } from 'react';
import { TypeInput } from '../types';

export enum ColorInput {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export enum VariantInput {
  outlined = 'outlined',
  filled = 'filled',
  standard = 'standard',
}

const MuiValidationTextField = styled(MaterialTextField)(({ theme, size }) => ({
  margin: '5px 0px',
  backgroundColor: 'transparent',
  '& .MuiFormHelperText-root.MuiFormHelperText-sizeMedium': {
    backgroundColor: 'transparent',
    padding: '0px 5px',
  },
  '& .MuiInputLabel-root.Mui-error': {
    borderColor: theme.palette.tertiary.red,
    color: theme.palette.tertiary.red,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl':
    {
      backgroundColor: theme.palette.paper.white,
    },
  '& .MuiInputLabel-root.Mui-error:focus': {
    borderColor: theme.palette.tertiary.red,
    color: theme.palette.tertiary.red,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    borderColor: theme.palette.tertiary.red,
    color: theme.palette.tertiary.red,
  },
  '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
    margin: 0,
    padding: '3px 12px 0px',
    color: theme.palette.grey[500],
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    padding: size === 'small' ? '6.5px 12px 6.5px 12px' : '9.5px 12px',
    color: 'theme.palette.grey[500]',
    borderColor: theme.palette.grey[900],
  },
  '& .MuiFormLabel-root': {
    color: theme.palette.grey[800],
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.secondary.dark,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.dark,
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
    backgroundColor: 'rgba(247, 249, 251, 1)',
    color: theme.palette.grey[800],
    opacity: '0.5',
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  '& .MuiSelect-select': {
    textAlign: 'left',
  },
}));

export type ValidationTextFieldProps = {
  label: string;
  variant?: VariantInput;
  color?: ColorInput;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  value?: string | number;
  size?: 'medium' | 'small';
  readOnly?: boolean;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  options?: { value: string; label: string }[];
  defaultOption?: boolean;
  type?: TypeInput;
  minLength?: string;
  maxLength?: string;
  id?: string;
  hidden?: boolean;
  role?: AriaRole | undefined;
  sx?: SxProps<Theme>;
  'aria-label'?: string;
  pattern?: string;
  name?: string;
  formElement?: boolean;
};

const ValidationTextField = ({
  variant = VariantInput.outlined,
  label,
  onChange,
  color,
  disabled,
  required,
  helperText,
  error,
  value,
  size,
  id,
  placeholder,
  minRows,
  maxRows,
  multiline,
  options,
  defaultOption,
  readOnly,
  maxLength,
  minLength,
  hidden,
  sx,
  pattern,
  role,
  type,
  'aria-label': ariaLabel,
  name,
  formElement,
  ...props
}: ValidationTextFieldProps) => {
  if (hidden === true) {
    type = TypeInput.hidden;
  }
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.value;
    setInputValue(updatedValue);
    onChange && onChange(event);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const formSX = useMemo(() => {
    if (formElement !== true) {
      return {};
    }
    return {
      '& input:valid + fieldset': {
        borderColor: 'green',
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
    };
  }, [formElement]);

  return (
    <MuiValidationTextField
      variant={variant}
      color={color}
      multiline={multiline}
      placeholder={placeholder}
      id={`${label}-${size}`}
      key={`${label}-${size}`}
      name={name}
      error={error}
      select={type === TypeInput.select}
      disabled={disabled}
      required={required}
      helperText={helperText ? helperText : ' '}
      label={label}
      value={inputValue}
      onChange={handleInputChange}
      fullWidth
      minRows={minRows}
      sx={{ display: hidden === true ? 'none' : 'block', label: { margin: '-5px 0px' }, ...formSX, ...sx }}
      maxRows={maxRows}
      size={size}
      type={type}
      role={role}
      aria-label={`container-${ariaLabel}`}
      inputProps={{
        maxLength,
        minLength,
        id: id,
        readOnly,
        pattern,
        'aria-label': ariaLabel,
        ...props,
      }}
      {...props}
    >
      {defaultOption && (
        <MenuItem key="" value="">
          -Choisir une option-
        </MenuItem>
      )}
      {options?.map((option: { value: string; label: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiValidationTextField>
  );
};

export default ValidationTextField;
export { ValidationTextField };
