import { Button } from '@bloomays-lib/ui.shared';
import DatePicker from '../atoms/DatePicker';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { cancel, notify } from '../../helpers/toastify';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { IMissionCardDetails } from '@bloomays-lib/types.shared';
import isAfter from 'date-fns/fp/isAfter';
import isBefore from 'date-fns/fp/isBefore';
import startOfToday from 'date-fns/startOfToday';
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import Checkbox from '../atoms/Checkbox';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isEqual } from 'lodash/fp';
import { useMutation } from '@apollo/client';
import {
  EXTEND_MISSION,
  GET_EXTENSION_ASSESSMENT,
  GET_ONE_FULL_MISSION,
  STOP_MISSION,
} from '@bloomays-lib/adapter.api-bloomer';
import { GraphQLError } from 'graphql';

export type ExtensionShortenMissionProps = {
  mission: IMissionCardDetails;
  handleClose: () => void;
  type: 'shorten' | 'extend';
  setPendingExtensionMission: Dispatch<SetStateAction<boolean>>;
};

export const shouldDisableEndDate = (type: string, endDate: Date) => (date: Date) => {
  if (type === 'shorten') {
    return isBefore(startOfToday(), date) || isAfter(endDate, date);
  }
  return isBefore(endDate, date);
};

export const shouldDisableConfirmDate = (pickedDate: Date | null) => (date: Date) => {
  if (!pickedDate) return true;
  if (isBefore(startOfToday(), date)) return true;
  if (isAfter(pickedDate, date)) return true;
  return false;
};

export const disabledButton = (loading: boolean, pickedDate: Date | null, confirmedDate: Date | null) => {
  if (loading) return true;
  if (pickedDate === null) return true;
  if (confirmedDate === null) return true;
  return !isEqual(pickedDate, confirmedDate);
};

const ExtensionShortenMission = ({
  mission,
  handleClose,
  type,
  setPendingExtensionMission,
}: ExtensionShortenMissionProps) => {
  const { t } = useTranslation(['extendShortenMission', 'notify']);
  const endDate = new Date(mission?.endMission as string);

  const [pickedDate, setPickedDate] = useState<Date | null>(null);
  const [confirmedDate, setConfirmedDate] = useState<Date | null>(null);
  const [useAmendmentTemplate, setUseAmendmentTemplate] = useState<boolean>(true);

  const [stopMission, { loading: pendingShortenMission }] = useMutation(STOP_MISSION, {
    refetchQueries: [
      {
        query: GET_ONE_FULL_MISSION,
        variables: {
          recordId: mission.recordId,
        },
      },
    ],
  });

  const [extensionMission, { loading: pendingExtensionMission }] = useMutation(EXTEND_MISSION, {
    refetchQueries: [
      {
        query: GET_ONE_FULL_MISSION,
        variables: {
          recordId: mission.recordId,
        },
      },
      {
        query: GET_EXTENSION_ASSESSMENT,
        variables: { missionId: mission.recordId },
      },
    ],
  });

  useEffect(() => {
    setPendingExtensionMission(pendingExtensionMission);
  }, [pendingExtensionMission]);

  useEffect(() => {
    setPendingExtensionMission(pendingShortenMission);
  }, [pendingShortenMission]);

  const handleError = (graphqlErrors: [GraphQLError]) => {
    if (graphqlErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT') {
      const invalidArgs = graphqlErrors?.[0]?.extensions?.invalidArgs as Array<{
        missing: string;
      }>;
      if (invalidArgs)
        notify(
          'error',
          t('missingFieldError', {
            ns: 'notify',
            missingField: invalidArgs[0].missing as string,
          }),
        );
    } else {
      notify('error', t('randomUpdateError'));
    }
  };

  const shortenMission = async (date: string) => {
    try {
      notify('info', t('updateInProgress', { ns: 'notify' }));
      const response = await stopMission({
        variables: {
          recordId: mission.recordId,
          endMission: date,
        },
      });
      if (response?.data.stopMission) {
        cancel();
        notify('success', t('updateSuccess', { ns: 'notify' }));
        handleClose();
      } else {
        cancel();
        handleError(response.errors as [GraphQLError]);
      }
    } catch (e: any) {
      cancel();
      handleError(e.graphQLErrors as [GraphQLError]);
    }
  };

  const extendMission = async (date: string, useAmendmentTemplate: boolean) => {
    try {
      notify('info', t('updateInProgress', { ns: 'notify' }));
      const response = await extensionMission({
        variables: {
          recordId: mission.recordId,
          endMission: date,
          useAmendmentTemplate,
        },
      });
      if (response?.data.extensionMission) {
        cancel();
        notify('success', t('updateSuccess', { ns: 'notify' }));
      } else {
        cancel();
        handleError(response.errors as [GraphQLError]);
      }
    } catch (e: any) {
      cancel();
      handleError(e.graphQLErrors as [GraphQLError]);
    }
  };

  if (!mission.contractType) {
    return <TitlePart textTitle={t('pickContractType')} />;
  }

  const contractAmendmentLabel =
    mission.contractType === 'bloomaysContract'
      ? 'generateAutomaticContractAmendmentBloomays'
      : 'generateAutomaticContractAmendmentNotBloomays';

  return (
    <Container>
      <ContainerStopExtend>
        <TitlePart textTitle={type === 'shorten' ? t('shortenTitle') : t('extendTitle')} />
        <SingleLineText text={type === 'shorten' ? t('sadText') : t('happyText')} />
        <SingleLineText text={t('newDateText')} />
      </ContainerStopExtend>
      <ContainerDates>
        <ContainerDate>
          <SingleLineText text={t('newDate')} />
          <DatePicker
            shouldDisableDate={shouldDisableEndDate(type, endDate)}
            openTo="day"
            views={['day']}
            label={t('pickDate')}
            handleChange={(e: Date | null) => setPickedDate(e)}
            value={pickedDate}
          />
        </ContainerDate>
        <ContainerDate>
          <SingleLineText text={t('newDateConfirm')} />
          <DatePicker
            shouldDisableDate={shouldDisableConfirmDate(pickedDate)}
            openTo="day"
            views={['day']}
            label={t('pickConfirmDate')}
            handleChange={(e: Date | null) => setConfirmedDate(e)}
            value={confirmedDate}
          />
        </ContainerDate>
        {type === 'extend' && (
          <ContainerDate>
            <Checkbox
              name="useAmendmentTemplate"
              checked={useAmendmentTemplate}
              label={t(contractAmendmentLabel)}
              labelPlacement="start"
              onChange={(e, checked) => {
                setUseAmendmentTemplate(checked);
              }}
            />
          </ContainerDate>
        )}
      </ContainerDates>
      <ContainerStopExtendButton>
        <Button textButton={t('cancel', { ns: 'random' })} onClick={() => handleClose()} />
        <Button
          disable={disabledButton(pendingExtensionMission, pickedDate, confirmedDate)}
          textButton={`${t('validate', { ns: 'random' })} ${type === 'shorten' ? t('stop') : t('extend')}`}
          onClick={async () => {
            if (mission && pickedDate) {
              type === 'extend'
                ? await extendMission(convertDateToStringFormat(pickedDate), useAmendmentTemplate)
                : await shortenMission(convertDateToStringFormat(pickedDate));
              setConfirmedDate(null);
              setPickedDate(null);
              handleClose();
            }
          }}
        />
      </ContainerStopExtendButton>
    </Container>
  );
};

export default ExtensionShortenMission;
export { ExtensionShortenMission };

const Container = styled('div')(() => ({
  padding: '30px',
}));

const ContainerStopExtend = styled('div')(() => ({
  textAlign: 'left',
}));

const ContainerStopExtendButton = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
}));

const ContainerDates = styled('div')(() => ({
  height: '30vh',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-around',
  margin: '20px 120px 20px 120px',
}));

const ContainerDate = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
