import { useTranslation } from 'react-i18next';
import { Button, SingleLineText, TitlePart } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import LabelAndSelectField from './LabelAndSelectField';
import { cancel, notify } from '../../helpers/toastify';
import DatePicker from '../atoms/DatePicker';
import isAfter from 'date-fns/isAfter';
import { ExtensionAssessmentGQL } from '@bloomays-lib/types.shared';
import { getAssessmentKey } from '../../helpers/locales';

export type ExtensionAssessmentProps = {
  missionId: string;
  currentEndDate: Date;
  upsertExtensionAssessment: (missionId: string, assessment: number, newEndDate: Date) => Promise<void>;
  currentExtensionAssessment: ExtensionAssessmentGQL | null;
};

export type ExtensionAssessmentState = {
  modified: boolean;
  assessment: number;
  newEndDate: Date | null;
};

export const shouldDisableDate = (currentEndDate: Date) => (date: Date) => {
  return isAfter(currentEndDate, date);
};

const CurrentExtensionAssessment = (props: { extensionAssessment: ExtensionAssessmentGQL | null }) => {
  const { t } = useTranslation(['extendShortenMission', 'random', 'notify']);
  const { extensionAssessment } = props;
  if (!extensionAssessment) {
    return null;
  }
  const assessmentLabel = t(getAssessmentKey(extensionAssessment.assessment));
  const assessmentLocale = extensionAssessment.assessment > 0.5 ? 'probableExtension' : 'improbableExtension';
  return (
    <ContainerExistingAssessment>
      <div
        dangerouslySetInnerHTML={{
          __html: t(assessmentLocale, {
            assessmentLabel,
            newEndDate: extensionAssessment.newEndDate,
            updatedAt: extensionAssessment.updatedAt,
            updatedBy: extensionAssessment.updatedBy,
          }),
        }}
      ></div>
    </ContainerExistingAssessment>
  );
};

export const ExtensionAssessment = ({
  missionId,
  currentEndDate,
  upsertExtensionAssessment,
  currentExtensionAssessment,
}: ExtensionAssessmentProps) => {
  const { t } = useTranslation(['extendShortenMission', 'random', 'notify']);
  const [popinState, setPopinState] = useState<ExtensionAssessmentState>({
    modified: false,
    assessment: 0,
    newEndDate: null,
  });

  const onSubmit = async (missionId: string, assessment: number, newEndDate: Date) => {
    const infoNotification = notify('info', t('updateInProgress', { ns: 'notify' }), null, {
      hideProgressBar: false,
      autoClose: 1000,
    });
    try {
      setPopinState({
        ...popinState,
        modified: false,
      });

      await upsertExtensionAssessment(missionId, assessment, newEndDate);

      notify('success', t('updateSuccess', { ns: 'notify' }), null, {
        hideProgressBar: false,
        autoClose: 1000,
      });
    } catch (e: any) {
      cancel(infoNotification);
      notify('error', e.message);
    }
  };

  const options = [
    { value: 0, label: '' },
    { value: 0.25, label: t('assessmentLabel1') },
    { value: 0.5, label: t('assessmentLabel2') },
    { value: 0.75, label: t('assessmentLabel3') },
    { value: 1, label: t('assessmentLabel4') },
  ];

  return (
    <ContainerExtensionAssessment>
      <TitlePart textTitle={t('assessmentTitle')} />
      <CurrentExtensionAssessment extensionAssessment={currentExtensionAssessment} />
      <ContainerDiv>
        <div dangerouslySetInnerHTML={{ __html: t('assessmentDescription') }}></div>
      </ContainerDiv>
      <ContainerDiv>
        <LabelAndSelectField
          value={popinState.assessment}
          name="assessment"
          textLabel={t('assesmentInputLabel')}
          options={options}
          required={true}
          defaultOption={false}
          handlechange={(e) => {
            const assessment = e.target.value as number;
            let modified = assessment !== 0;
            let newEndDate = popinState.newEndDate;
            if (assessment > 0.5) {
              modified = popinState.newEndDate !== null && isAfter(popinState.newEndDate, currentEndDate);
            } else {
              newEndDate = new Date();
            }
            setPopinState({
              ...popinState,
              assessment,
              modified,
              newEndDate,
            });
          }}
        />
      </ContainerDiv>
      {popinState.assessment > 0.5 ? (
        <ContainerDate>
          <SingleLineText text={t('newDate')} />
          <DatePicker
            shouldDisableDate={shouldDisableDate(currentEndDate)}
            openTo="day"
            views={['day']}
            label={t('pickDate')}
            handleChange={(selectedDate: Date | null) => {
              const modified = selectedDate !== null && popinState.assessment !== 0;
              setPopinState({
                ...popinState,
                newEndDate: selectedDate,
                modified,
              });
            }}
            value={popinState.newEndDate}
          />
        </ContainerDate>
      ) : null}
      <ContainerButton>
        <Button
          textButton={t('save', { ns: 'random' })}
          disable={!popinState.modified}
          onClick={async () => {
            if (!popinState.newEndDate) {
              return;
            }
            await onSubmit(missionId, popinState.assessment, popinState.newEndDate);
          }}
        />
      </ContainerButton>
    </ContainerExtensionAssessment>
  );
};

const ContainerExtensionAssessment = styled('div')(() => ({
  padding: '50px',
  textAlign: 'left',
}));

const ContainerExistingAssessment = styled('div')(
  ({ theme }) => `
    background-color: ${theme.palette.grey[400]};
    padding: 20px;
  `,
);

const ContainerDiv = styled('div')(() => ({
  marginTop: '20px',
  marginBottom: '20px',
}));

const ContainerButton = styled('div')(() => ({
  textAlign: 'right',
  paddingLeft: '50px',
}));

const ContainerDate = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '20px',
}));
