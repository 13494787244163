import { User } from '@auth0/auth0-react';
import React, { createContext, useContext } from 'react';
import { useQuery } from '../customHooks/useQuery';
import { Auth0UserType } from '@bloomays-lib/types.shared';
import { UserFactory } from './authService';

export const AuthContext = createContext<Auth0UserType | null>(null);

export const AuthProvider = (props: {
  children: React.ReactNode;
  location: string;
  userFactory?: any;
  user0: User | undefined;
  isAuthenticated0: boolean;
  isLoading0: boolean;
  error0: Error | undefined;
  logout0: (e: { returnTo: string }) => void;
  loginWithRedirect0: (e: { appState: { target: string } }) => void;
  getAccessTokenSilently0?: () => void;
}): JSX.Element => {
  const factoryFn = props.userFactory || UserFactory;
  const result = factoryFn({
    useQuery,
    ...props,
  });
  return <AuthContext.Provider value={result}>{props.children}</AuthContext.Provider>;
};

export const useAuth = (): Auth0UserType | null => {
  return useContext(AuthContext);
};
