import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import { notify } from '../../helpers/toastify';

import { GET_EMBED_CLIENT_INVITE } from '@bloomays-lib/adapter.api-bloomer';

import Iframe from '../atoms/Iframe';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import FallbackError from '../organisms/FallbackError';
import path from 'path';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import { getAppoloErrorException, getApolloErrorCode } from '../../helpers/error';

const EmbedCRAInviteClient = (): JSX.Element => {
  const [result, setResult] = useState<string>();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(['random', 'notify']);

  const [getEmbedInviteClientCRA, { loading, error }] = useMutation<
    { getEmbedInviteClientCRA: string },
    { signingId: string }
  >(GET_EMBED_CLIENT_INVITE);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (id) {
        try {
          const getEmbedLink = await getEmbedInviteClientCRA({
            variables: {
              signingId: id,
            },
          });
          setResult(getEmbedLink?.data?.getEmbedInviteClientCRA);
        } catch (e) {
          console.warn('Handled error in useEffect to get embed cra invite', e);
          return;
        }
      }
    })();
  }, [getEmbedInviteClientCRA, id]);

  useEffect(() => {
    if (error) {
      const code = getApolloErrorCode(error);
      const exception = getAppoloErrorException(error);
      const activity = exception?.meta?.activity;
      switch (code) {
        case 'TOKEN_EXPIRED':
          notify('warning', t('expiredLink', { ns: 'notify' }));
          break;
        case 'ALREADY_SIGNED':
          notify('info', t('craAlreadySigned', { ns: 'notify' }));
          if (activity) {
            navigate(
              `/files/activity/${convertDateToStringFormat(activity.month, 'yyyy-MM-dd')}/${
                activity.recordId
              }/cra/${path.basename(activity.urlCraS3)}`,
            );
          }
          break;
        case 'OBJECT_NOT_FOUND':
          notify('warning', t('invalidInviteLink', { ns: 'notify' }));
          break;
        default:
          notify('error', t('linkError', { ns: 'notify' }), error);
      }
    }
    return;
  }, [error, navigate, t]);

  const code = getApolloErrorCode(error);
  if (code === 'OBJECT_NOT_FOUND') {
    return <FallbackError errorText={t('invalidDoc', { ns: 'notify' })} />;
  }
  if (loading) return <LoaderSkeleton label={t('loadingText', { ns: 'random' })} height={600} width={800} />;

  if (!result) {
    return <LoaderSkeleton label={t('linkError', { ns: 'notify' })} height={600} width={800} />;
  } else {
    return <Iframe url={result} />;
  }
};

export default WithErrorBoundary(EmbedCRAInviteClient);
