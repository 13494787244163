import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar } from '@bloomays-lib/ui.shared';
import NameTalent from '../atoms/NameTalent';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import ListDetailInfo from '../atoms/ListDetailInfo';
import ItemTalentLevelList from './ItemTalentLevelList';
import location from '../../graphic-charter/icons/location.svg';
import euro from '../../graphic-charter/icons/euro.svg';
import building from '../../graphic-charter/icons/building.svg';
import { useTranslation } from 'react-i18next';
import { ITalent } from '@bloomays-lib/types.shared';
import orderBy from 'lodash/orderBy';
import { Logo } from '@bloomays-lib/ui.shared';
import lk from '../../graphic-charter/icons/LK.svg';
import cv from '../../graphic-charter/icons/CV.svg';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { CardItem } from '@bloomays-lib/ui.shared';
import Modal from '../atoms/Modal';
import SyncTalentQualification from './SyncTalentQualification';
import TalentSheet from '../organisms/TalentSheet';
import { getRemoteWork, getStatus } from '../organisms/TalentSheetSideBar';
import { jobTitleTalent } from '../../services/serviceTalent';
import { _getSkillsSorted } from '../../helpers/skills';
import { getSentenceJob } from '../../helpers/talentTool';

export type TalentItemCardProps = {
  talent: ITalent;
};

const getDetails = (talent: any) => {
  const details = [];
  if (talent?.location && talent?.location?.city) {
    details.push({ icon: location, text: talent.location.city });
  }
  if (talent?.status) {
    details.push({ text: getStatus(talent.status) });
  }
  if (talent?.package && talent?.tjm) {
    details.push({ icon: euro, text: `${talent.tjm}/${talent.package}€` });
  }
  if (talent?.tjm && !talent?.package) {
    details.push({ icon: euro, text: `${talent.tjm}€` });
  }
  if (talent?.package && !talent?.tjm) {
    details.push({ icon: euro, text: `${talent.package}€` });
  }
  if (talent?.remoteWork) {
    details.push({ icon: building, text: getRemoteWork(talent.remoteWork) });
  }
  return details;
};

const TalentItemCard = ({ talent }: TalentItemCardProps) => {
  const [openSyncTalent, setOpenSyncTalent] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const { t } = useTranslation(['talentTool']);

  const handleCloseSyncTalent = () => {
    setOpenSyncTalent(false);
  };

  const handleOpenSyncTalent = () => {
    setOpenSyncTalent(true);
  };

  const handleCloseSheet = () => {
    setOpenSheet(false);
  };

  const handleOpenSheet = () => {
    setOpenSheet(true);
  };

  const currentJob = talent?.jobs?.filter((job: any) => job.current === true)[0];
  let passedJob;
  if (talent.jobs && talent.jobs.length > 0) {
    passedJob = orderBy(talent?.jobs, (item) => item?.period?.startDate, ['desc']).filter((job) => !job.current)[0];
  }

  const fullname = `${talent.firstname} ${talent.lastname}`;

  const fullnameReduced = fullname?.length > 18 ? `${fullname?.substring(0, 18)}...` : fullname;

  const getJobTitleReduced = (talent: ITalent) => {
    if (talent?.positionTitle?.jobTitle?.label) {
      if (talent?.positionTitle?.jobTitle?.label.length > 18) {
        return `${talent.positionTitle?.jobTitle?.label?.substring(0, 18)}...`;
      }
      return talent?.positionTitle?.jobTitle?.label;
    }
    if (talent?.jobTitle) {
      if (talent?.jobTitle.length > 18) {
        return `${talent.jobTitle?.substring(0, 18)}...`;
      }
      return talent.jobTitle;
    }
  };

  const LK = talent?.profiles?.filter((profile) => profile.network?.toLocaleLowerCase() === 'linkedin')?.[0];

  return (
    <StyledCardContainer>
      <Modal
        additionnalCSS={{
          overflowY: 'scroll',
          width: '65%',
        }}
        open={openSyncTalent}
        onClose={handleCloseSyncTalent}
      >
        {talent && talent?.id && (
          <SyncTalentQualification
            handleClose={handleCloseSyncTalent}
            candidate={{
              lastname: talent.lastname,
              firstname: talent.firstname,
              jobTitle: jobTitleTalent(talent),
              linkedInUrl: LK?.url,
              profilPictureUrl: talent?.image,
              email: talent?.email,
              phoneNumber: talent.phone,
              externalId: talent.id,
            }}
          />
        )}
      </Modal>
      {talent && talent.id && (
        <Modal
          additionnalCSS={{
            overflowY: 'scroll',
            width: '95%',
            height: '100vh',
            left: '52.5%',
            maxHeight: '100vh',
            padding: '0px',
          }}
          additionnalCSSCross={{
            float: 'left',
            position: 'absolute',
            zIndex: '2',
            marginLeft: '24px',
            marginTop: '24px',
          }}
          open={openSheet}
          onClose={handleCloseSheet}
        >
          <TalentSheet
            origin="searchPage"
            id={talent.id}
            handleOpenSyncTalent={handleOpenSyncTalent}
            openSyncTalent={openSyncTalent}
            handleCloseSyncTalent={handleCloseSyncTalent}
          />
        </Modal>
      )}
      <StyledAvatarNameJob onClick={() => handleOpenSheet()}>
        <Avatar image={talent?.image} size={'large'} alt={fullnameReduced} />
        <NameTalent name={fullnameReduced} statusBloomer={talent?.bloomerStatus} />
        <StyledJobContainer>
          <SingleLineText text={getJobTitleReduced(talent)} />
        </StyledJobContainer>
      </StyledAvatarNameJob>
      <StyledBodyContainer onClick={() => handleOpenSheet()}>
        <ListDetailInfo list={getDetails(talent)} />
        {/* Positionné sur X job */}
        {talent?.skillLevels && <ItemTalentLevelList items={_getSkillsSorted(talent?.skillLevels)} reduced />}
        <StyledJobsContainer>
          <SingleLineText variant={'body2Medium'} text={`${t('currentJob')}: `} />
          <SingleLineText variant={'body1Regular'} text={currentJob ? getSentenceJob(currentJob, t) : '-'} />
        </StyledJobsContainer>
        <StyledJobsContainer>
          <SingleLineText variant={'body2Medium'} text={`${t('jobPassed')}: `} />
          <SingleLineText variant={'body1Regular'} text={passedJob ? getSentenceJob(passedJob, t) : '-'} />
        </StyledJobsContainer>
      </StyledBodyContainer>
      <StyledActionContainer>
        {/* <Checkbox
          labelPlacement={'start'}
          name={'Select'}
          label={t('select')}
        /> */}
        <StyledIconContainer>
          {LK?.url && <StyledLogo image={lk} onClick={() => LK?.url && window.open(LK?.url)} alt="Linkedin Url" />}
          {talent?.cv && <StyledLogo image={cv} onClick={() => talent?.cv && window.open(talent?.cv)} alt="CV" />}

          <CardItem
            onClick={handleOpenSyncTalent}
            variant={'pink'}
            size={'extraSmall'}
            emoji={<AddShoppingCartIcon />}
          />
        </StyledIconContainer>
      </StyledActionContainer>
    </StyledCardContainer>
  );
};

export default TalentItemCard;
export { TalentItemCard };

const StyledCardContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  border-radius: 12px;
  padding: 16px;
  min-width: 690px;
  box-shadow: 0px 4.38px 24.1px rgba(75, 75, 75, 0.09);
  background-color: ${theme.palette.paper.white};
  `,
);

const StyledAvatarNameJob = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-right: 24px;
  gap: 10px;
  border-right: 1px solid ${theme.palette.grey[900]};
  &:hover {
    cursor: pointer;
  }
  `,
);

const StyledBodyContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  &:hover {
    cursor: pointer;
  }
  `,
);

const StyledIconContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 12px;
  `,
);

const StyledLogo = styled(Logo)(
  () => `
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer
  }
  `,
);

const StyledJobsContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  color: ${theme.palette.grey[50]};
  `,
);

const StyledActionContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  `,
);

const StyledJobContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  border-radius: 7px;
  width: 186px;
  height: 28px;
  pading: 4px;
  background-color: ${theme.palette.grey[200]};
  color: ${theme.palette.grey[500]};
  `,
);
