import { gql } from '@apollo/client';

export const ME_NOTIFICATION = gql`
  query me {
    me {
      bloomer {
        recordId
        notification {
          recordId
          title
          description
          priority
          type
          read
          canDelete
          creationDate
          mission
          society
        }
      }
    }
  }
`;

export const ME_FULL = gql`
  query me {
    me {
      profile {
        id
        provider
        roles
        name {
          familyName
          givenName
        }
        email
      }
      bloomer {
        invitation {
          recordId
          token
          status
        }
        recordId
        fullname
        firstname
        lastname
        email
        slack
        secondaryEmail
        phoneNumber
        communicationType
        accountManager {
          fullname
          recordId
        }
        credentialID
        mission {
          isActive
          recordId
          label
          beginMission
          endMission
          CSM {
            fullname
          }
          clientSociety {
            name
          }
          pricing {
            purchasePricePerDayWithoutTaxes
            startDate
          }
          bloomerBillingSociety {
            recordId
            name
            status
            siret
            KBIS
            IBAN
            BIC
            URSSAFVigilanceCertificate
            RCPVigilanceCertificate
            portage
            addressCountryCode
            tva
            billingContact {
              recordId
              fullname
              firstname
              lastname
              email
              phoneNumber
              nameJob
            }
          }
        }
      }
    }
  }
`;

export const ME_LIGHT = gql`
  query me {
    me {
      profile {
        id
        provider
        roles
        name {
          familyName
          givenName
        }
        email
      }
      bloomer {
        recordId
        fullname
        firstname
        lastname
        email
        slack
        secondaryEmail
        phoneNumber
        communicationType
      }
    }
  }
`;

export const ALL_BLOOMERS = gql`
  query bloomers {
    bloomers {
      fullname
      recordId
      firstname
      lastname
      email
      phoneNumber
      slack
      accountManager {
        recordId
        fullname
      }
    }
  }
`;

export const ALL_MISSIONS = gql`
  query allMissions($isActive: Boolean, $missionIds: [String]) {
    allMissions(isActive: $isActive, missionIds: $missionIds) {
      count
      results {
        recordId
        label
        beginMission
        bloomerFullName
        endMission
        clientSocietyName
        bloomerSocietyName
        createdAt
        completed
        isActive
        replacement
        type
      }
    }
  }
`;

export const ALL_MISSIONS_WITH_EXTENSION = gql`
  query allMissions($missionIds: [String]) {
    allMissions(missionIds: $missionIds) {
      count
      results {
        recordId
        label
        beginMission
        bloomerFullName
        endMission
        createdAt
        isActive
        clientSocietyName
        replacement
        extension {
          assessment
          newEndDate
        }
        type
      }
    }
  }
`;

export const GET_ONE_ACTIVITY = gql`
  query activities($recordId: ID, $month: Date, $missionRecordId: String) {
    activities(recordId: $recordId, month: $month, missionRecordId: $missionRecordId) {
      recordId
      mission {
        recordId
      }
      month
      nbDaysWorked
      nbDaysMarkupWorked
      nbDaysMarkup2Worked
      nbDaysDetails
      astreinte
      billed
      validated
      signing
      signingDocumentId
      signedCount
      urlCraS3
      urlInvoicesS3
      signingEmbedBloomerId
      payed
    }
  }
`;

export const ACTIVITIES_FOR_ONE_MISSION = gql`
  query missions($recordId: String) {
    missions(recordId: $recordId) {
      recordId
      endMission
      enableCRAUpload
      contactOperations {
        email
      }
      bloomerBillingSociety {
        portage
      }
      activity {
        recordId
        month
        nbDaysWorked
        nbDaysMarkupWorked
        nbDaysMarkup2Worked
        nbDaysDetails
        astreinte
        billed
        validated
        signing
        signingDocumentId
        signedCount
        urlCraS3
        urlInvoicesS3
        urlAdditionalInvoicesS3
        signingEmbedBloomerId
        payed
        lastSupplierError
      }
      clientSocietyName
    }
  }
`;

export const ACTIVITIES_FOR_ONE_MISSION_LIGHT = gql`
  query missions($recordId: ID) {
    missions(recordId: $recordId) {
      bloomerFullName
      label
      bloomerPaydateDays
      clientSocietyName
      activity {
        recordId
        month
        payed
        dateUploadInvoice
        CRAStatus
        urlInvoicesS3
      }
    }
  }
`;

export const MISSING_CRA = gql`
  query getMissingCRA($month: DateTime) {
    getMissingCRA(month: $month) {
      __typename
      ... on Mission {
        recordId
        bloomerFullName
        clientSocietyName
      }
      ... on Activity {
        bloomerFullname
        clientMission
        CRAStatus
        signingDocumentId
        mission {
          recordId
        }
      }
    }
  }
`;

export const ALL_AM = gql`
  query allAccountManagers {
    allAccountManagers {
      fullname
      recordId
    }
  }
`;

export const GET_ONE_FULL_MISSION = gql`
  query missions($recordId: String) {
    missions(recordId: $recordId) {
      recordId
      isActive
      replacement
      isDeal
      label
      beginMission
      endMission
      contractType
      clientPaydateDays
      bloomerPaydateDays
      billingContacts {
        fullname
        firstname
        lastname
        email
        phoneNumber
        recordId
        nameJob
      }
      type
      CSM {
        fullname
      }
      pricing {
        taxes
        purchasePricePerDayWithoutTaxes
        salePricePerDayWithoutTaxes
        startDate
      }
      bloomer {
        fullname
        recordId
        firstname
        lastname
        email
        phoneNumber
        slack
        credentialID
        accountManager {
          recordId
          fullname
        }
      }
      contactOperations {
        fullname
        firstname
        lastname
        email
        phoneNumber
        recordId
        nameJob
      }
      clientSociety {
        name
        status
        siret
        recordId
        billingContact {
          fullname
          firstname
          lastname
          email
          phoneNumber
          recordId
          nameJob
        }
      }
      clientBillingSociety {
        name
        status
        siret
        recordId
        billingContact {
          fullname
          firstname
          lastname
          email
          phoneNumber
          recordId
          nameJob
        }
      }
      bloomerBillingSociety {
        name
        status
        siret
        recordId
        portage
        KBIS
        IBAN
        BIC
        URSSAFVigilanceCertificate
        URSSAFVCExpirationDate
        RCPVigilanceCertificate
        billingContact {
          fullname
          firstname
          lastname
          email
          phoneNumber
          recordId
          nameJob
        }
      }
    }
  }
`;

export const GET_MISSION_COMPLETION = gql`
  query missions($recordId: String) {
    missions(recordId: $recordId) {
      completion {
        complete
        bloomer
        clientSociety
        bloomerSociety
        mission
        pricing
        billingContact
        operationalContact
      }
    }
  }
`;

export const CHECK_INVITATION_TOKEN = gql`
  query checkInvitationToken($token: String!) {
    checkInvitationToken(token: $token) {
      recordId
      status
      mission
    }
  }
`;

export const GET_JOBS_HISTORICAL = gql`
  query jobHistorical($id: String!, $jobName: String!) {
    jobHistorical(id: $id, jobName: $jobName) {
      processedOn
      id
      progress
      error
      data {
        contact
      }
    }
  }
`;

export const RECRUITEMENTS = gql`
  query getRecruitements($isTrialPeriod: Boolean) {
    getRecruitements(isTrialPeriod: $isTrialPeriod) {
      CustomerName
      SalesPriceWithoutTaxes
      TrialPeriodEndDate
      BillingDate
      Description
      nextStep
      recordId
      Billed
      externalId
    }
  }
`;

export const BOOM = gql`
  query boom($code: Int) {
    boom(code: $code)
  }
`;

export const FINANCES = gql`
  query getFinances($beginDate: DateTime, $endDate: DateTime, $isExpense: Boolean) {
    getFinances(beginDate: $beginDate, endDate: $endDate, isExpense: $isExpense) {
      date
      price
      financeType
      year
      isExpense
      isForecast
    }
  }
`;

export const FINANCE_ONE_MONTH = gql`
  query getFinanceByMonth($date: DateTime, $isExpense: Boolean) {
    getFinanceByMonth(date: $date, isExpense: $isExpense) {
      date
      price
      financeType
      year
      isExpense
      isForecast
    }
  }
`;

export const ONE_JOB_CLIENT = gql`
  query getJobProcess($id: ID!) {
    jobProcess(id: $id) {
      type
      title
      duration
      localization
      startDate
      salesPriceWithoutTaxes
      description
      scorecards
      nbProfilLKContacted
      nbProfilPhoneContacted
      nbProfilEmailContacted
      responsePerPercentage
      nbQualifProfil
      talentsProcess {
        phase
        lastname
        firstname
        location
        jobTitle
        availableDate
        salesPriceWithoutTaxes
        pluses
        emailMessagingAddress
        profilPictureUrl
        linkedInUrl
        referenceAccepted
        experiences
        CV
        noGoReason
        noGoExplanation
      }
    }
  }
`;

export const JOBS_CARDS = gql`
  query getJobCards {
    getJobCards {
      id
      title
      url
      clientName
    }
  }
`;

export const MISSION = gql`
  query missions($activityMonth: DateTime, $isMissingCRA: Boolean, $recordId: String) {
    missions(recordId: $recordId, activityMonth: $activityMonth, isMissingCRA: $isMissingCRA) {
      bloomerFullName
      label
      clientSocietyName
      statusBloomer
      recordId
      bloomerPaydateDays
      beginMission
      endMission
      CSM {
        firstname
      }
      activity {
        bloomerFullname
        signingDocumentId
        month
        CRAStatus
        urlInvoicesS3
        urlAdditionalInvoicesS3
        recordId
        billed
        dateUploadInvoice
        clientMission
        validated
        lastError
        payed
      }
    }
  }
`;

export const GET_MISSION_FOLLOWUP = gql`
  query getMissionFollowUp($month: String, $type: String, $activityId: String) {
    getMissionFollowUp(month: $month, type: $type, activityId: $activityId) {
      recordId
      score
      comment
      fullName
      missionLabel
    }
  }
`;

export const DASHBOARD_MISSION_DATA = gql`
  query dashboardMissionData($startDate: DateTime, $extended: Boolean) {
    dashboardMissionData(startDate: $startDate, extended: $extended) {
      D {
        currentYear {
          month
          count
          missionIds
        }
        lastYear {
          month
          count
          missionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
      NS {
        currentYear {
          month
          count
          missionIds
        }
        lastYear {
          month
          count
          missionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
      M {
        currentYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        lastYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
      F {
        currentYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        lastYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
      EB {
        currentYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        lastYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
      BE {
        currentYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        lastYear {
          month
          count
          missionIds
          estimatedMissionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
      BR {
        currentYear {
          month
          count
          missionIds
        }
        lastYear {
          month
          count
          missionIds
        }
        diff {
          month
          count
        }
        average {
          currentYear
          lastYear
        }
      }
    }
  }
`;

export const GET_EXTENSION_ASSESSMENT = gql`
  query getExtensionAssessment($missionId: String!) {
    getExtensionAssessment(missionId: $missionId) {
      missionId
      assessment
      newEndDate
      updatedBy
      updatedAt
    }
  }
`;

const fieldForActivity = `{
  missionId
  bloomerFullName
  clientSocietyName
  contactOperationsName
  activityId
  activityMonth
  status
  craURL
  invoiceURL
  signingDocumentId
  bloomerPayDateDays
  dateUploadInvoice
  bloomerInvoiceBilled
  bloomerInvoiceStatus
  bloomerInvoiceLastError
  bloomerSocietyName
  clientInvoiceStatus
  clientInvoiceLastError
  bloomerScore
  bloomerComment
  bloomerScoreDate
  clientScore
  clientComment
  clientScoreDate
}`;

export const GET_CRA_BY_MONTH = gql`
  query getCraByMonth($month: DateTime) {
    getCraByMonth(month: $month) 
    ${fieldForActivity}
  }
`;

export const GET_CRA_BY_MISSION = gql`
  query getCraByMission($missionId: ID!) {
    getCraByMission(missionId: $missionId) 
    ${fieldForActivity}
  }
`;

export const GET_LEGAL_CONTRACTS_BY_MISSION = gql`
  query getLegalContractsByMission($missionId: String!) {
    getLegalContractsByMission(missionId: $missionId) {
      mission {
        id
        label
        bloomerId
        bloomerFullName
        clientSocietyId
        clientSocietyName
        bloomerSocietyName
      }
      contracts {
        id
        recordId
        createdAt
        createdDate
        signedDate
        type
        externalDocumentId
        nbSigner
        signedCount
        signerDocId1
        provider
        status
        contractTemplate
        step
        emptyFileUrl
        signedFileUrl
      }
    }
  }
`;

export const GET_LEGAL_CONTRACTS = gql`
  query getLegalContracts($status: MissionStatusFilter!) {
    getLegalContracts(status: $status) {
      mission {
        id
        label
        bloomerId
        bloomerFullName
        clientSocietyId
        clientSocietyName
        bloomerSocietyName
      }
      contracts {
        id
        recordId
        createdAt
        createdDate
        signedDate
        type
        externalDocumentId
        nbSigner
        signedCount
        signerDocId1
        provider
        status
        contractTemplate
        step
        emptyFileUrl
        signedFileUrl
      }
    }
  }
`;
