import { BloomerStatus, Item, Paginate, RemoteWork, Status } from '@bloomays-lib/types.shared';

export enum ActionType {
  ADD_SKILLS_SEARCH = 'ADD_SKILLS_SEARCH',
  RM_SKILLS_SEARCH = 'RM_SKILLS_SEARCH',
  ADD_JOBS_SEARCH = 'ADD_JOBS_SEARCH',
  RM_JOBS_SEARCH = 'RM_JOBS_SEARCH',
  TEXT_SEARCH = 'TEXT_SEARCH',
  STATUS_SEARCH = 'STATUS_SEARCH',
  AVAILABILITY_SEARCH = 'AVAILABILITY_SEARCH',
  REMOTE_WORK_SEARCH = 'REMOTE_WORK_SEARCH',
  RESET_SEARCH = 'RESET_SEARCH',
  ADD_OWNERS_SEARCH = 'ADD_OWNERS_SEARCH',
  RM_OWNERS_SEARCH = 'RM_OWNERS_SEARCH',
  ADD_LANGUAGES_SEARCH = 'ADD_LANGUAGES_SEARCH',
  RM_LANGUAGES_SEARCH = 'RM_LANGUAGES_SEARCH',
  BLOOMER_STATUS_SEARCH = 'BLOOMER_STATUS_SEARCH',
  PAGINATE = 'PAGINATE',
  LEVEL_GLOBAL_SKILLS_SEARCH = 'LEVEL_GLOBAL_SKILLS_SEARCH',
  TOGGLE_CHANGE = 'TOGGLE_CHANGE',
  RANGE_CHANGE = 'RANGE_CHANGE',
}

export type Action = {
  type: ActionType;
  skills?: Item[];
  skillsId?: string[];
  jobsId?: string[];
  languagesLabel?: string[];
  jobs?: Item[];
  fulltext?: string;
  owners?: string[];
  languages?: { label: string }[];
  status?: Status;
  bloomerStatus?: BloomerStatus;
  remoteWork?: RemoteWork;
  tjm?: number;
  package?: number;
  globalLevelSkills?: number;
  paginate?: Paginate;
  availabilityDate?: Date | null;
};

export type State = {
  search: {
    fulltext?: string;
    status?: Status;
    bloomerStatus?: BloomerStatus;
    remoteWork?: RemoteWork;
    tjm?: number;
    package?: number;
    availabilityDate?: Date | null;
    owners?: string[];
    languages?: { label: string }[];
    jobs?: {
      jobs?: Item[];
    };
    skills?: {
      skills?: Item[];
    };
  };
  paginate: Paginate;
};

export type ToggleChoices = Record<string, boolean>;

export type ToogleChangeAction = {
  type: ActionType.TOGGLE_CHANGE;
  payload: ToggleChoices;
  meta: {
    key: string;
  };
};

export type RangeValues = [number, number];
export type RangeChangeAction = {
  type: ActionType.RANGE_CHANGE;
  payload: RangeValues;
  meta: {
    key: string;
  };
};
