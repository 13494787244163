import { styled } from '@mui/material/styles';
import { ITalent } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import path from 'path';
import { Embed } from '../atoms/Embed';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { Upload } from '../molecules/Upload';

export type TalentSheetCVProps = {
  talent?: ITalent;
  uploadCv: any;
  CVRef: React.MutableRefObject<null>;
};

const TalentSheetCV = ({ talent, uploadCv, CVRef }: TalentSheetCVProps) => {
  const { t } = useTranslation(['talentSheet', 'random']);

  return (
    <div>
      <StyledXP>
        {talent?.cv && !talent?.cv.includes('linkedin') && (
          <Upload
            id="upload"
            ref={CVRef}
            titleModal={t('titleModalUploadCV1')}
            subTitleModal={t('titleModalUploadCV2')}
            placeholder="CV"
            fileTypes={['.pdf']}
            textLabel={t('updateCV', { ns: 'talentSheet' })}
            maxFiles={1}
            required={false}
            displayIcons={false}
            autoload={true}
            preloadedFiles={talent?.cv ? [{ url: talent.cv, path: path.basename(talent.cv) }] : []}
            handleChange={async (files) => {
              return await uploadCv(files);
            }}
            maxLength={5000000}
          />
        )}

        {talent?.cv && !talent?.cv.includes('linkedin') ? (
          <Embed src={talent?.cv} height={780} />
        ) : (
          <div>
            <SingleLineText text={t('noCvOrLK', { ns: 'random' })} />
            <Upload
              id="upload"
              ref={CVRef}
              placeholder="CV"
              fileTypes={['.pdf']}
              textLabel="Importer un CV"
              titleModal={t('titleModalUploadCV1')}
              subTitleModal={t('titleModalUploadCV2')}
              maxFiles={1}
              required={false}
              autoload={true}
              preloadedFiles={talent?.cv ? [{ url: talent.cv, path: path.basename(talent.cv) }] : []}
              handleChange={async (files) => {
                return await uploadCv(files);
              }}
              maxLength={5000000}
            />
          </div>
        )}
      </StyledXP>
    </div>
  );
};

export default TalentSheetCV;
export { TalentSheetCV };

const StyledXP = styled('div')(
  () => `
  box-shadow: 0px 4.30769px 23.6923px rgba(75, 75, 75, 0.09);
  border-radius: 10px;
  text-align: left;
  padding: 24px 24px 32px 24px;
  margin-bottom: 24px;
  `,
);
