import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import ContextMissionDetails from '../organisms/ContextMissionDetails';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import {
  BrowserBloomer,
  IContact,
  IMissionCardDetails,
  ISociety,
  IBloomerCompletion,
  GetOneFullMissionResponse,
  GetOneFullMissionParams,
} from '@bloomays-lib/types.shared';
import { GET_ONE_FULL_MISSION, GET_MISSION_COMPLETION } from '@bloomays-lib/adapter.api-bloomer';
import { errorLogger } from '../../helpers/error';
import { getFirstValidPrice, greaterDate } from '@bloomays-lib/utils.shared';
import { notify } from '../../helpers/toastify';

interface FormData {
  bloomer?: BrowserBloomer;
  clientSociety?: ISociety;
  clientBillingSociety?: ISociety;
  contextContactBillingClient?: IContact | IContact[];
  bloomerSociety?: ISociety;
  contactBloomerSociety?: IContact;
  contactOperations?: IContact;
  mission?: IMissionCardDetails;
  missionCompletion?: IBloomerCompletion;
}

const MissionDetails = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error('Missing ID params');
  }

  const [getMission, { loading, error, data: dataMission }] = useLazyQuery<
    GetOneFullMissionResponse,
    GetOneFullMissionParams
  >(GET_ONE_FULL_MISSION, {
    variables: {
      recordId: id,
    },
  });

  const [getMissionCompletion, { error: errorCompletion, data: dataMissionCompletion }] = useLazyQuery<
    GetOneFullMissionResponse,
    GetOneFullMissionParams
  >(GET_MISSION_COMPLETION, {
    variables: {
      recordId: id,
    },
  });

  const [data, setData] = useState<FormData>();

  React.useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getMission();

      const mission = dataMission?.missions?.[0];
      const missionCardDetails = { ...mission } as IMissionCardDetails;

      const activePricing = getFirstValidPrice(
        [...(mission?.pricing || [])],
        greaterDate(new Date(mission?.beginMission as string), new Date()),
      );
      missionCardDetails.purchasePricePerDayWithoutTaxes = activePricing?.purchasePricePerDayWithoutTaxes;
      missionCardDetails.salePricePerDayWithoutTaxes = activePricing?.salePricePerDayWithoutTaxes;
      missionCardDetails.taxes = mission?.pricing?.[0]?.taxes;
      missionCardDetails.CSM = mission?.CSM?.fullname || 'Non attribué';
      missionCardDetails.completion = mission?.completion;
      missionCardDetails.prices = mission?.pricing?.map((price) => ({
        ...price,
        startDate: price.startDate ? new Date(price.startDate) : undefined,
      }));

      setData((prevData) => ({
        ...prevData,
        bloomer: mission?.bloomer,
        clientSociety: mission?.clientSociety,
        clientBillingSociety: mission?.clientBillingSociety,
        contextContactBillingClient: mission?.billingContacts || mission?.clientSociety?.billingContact,
        bloomerSociety: mission?.bloomerBillingSociety?.[0],
        contactBloomerSociety: mission?.bloomerBillingSociety?.[0]?.billingContact,
        contactOperations: mission?.contactOperations,
        mission: missionCardDetails,
      }));
    }
  }, [dataMission, getMission, id]);

  React.useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getMissionCompletion();

      const missionCompletion = dataMissionCompletion?.missions?.[0].completion;
      if (missionCompletion) {
        setData((prevData) => ({
          ...prevData,
          missionCompletion: missionCompletion,
        }));
      }
    }
  }, [dataMissionCompletion, getMissionCompletion, id]);

  React.useEffect(() => {
    if (error) {
      errorLogger(error, {
        extraInfos: 'Internal server error, impossible to retrieve data about contact bloomer / mission !',
        state: {},
      });
      notify(
        'error',
        'Impossible de récupérer les données de mission. Erreur du server, essaie plus tard ! 😓 ',
        error,
        undefined,
      );
    }
    if (errorCompletion) {
      errorLogger(errorCompletion, {
        extraInfos: 'Internal server error, impossible to retrieve data about contact bloomer / mission !',
        state: {},
      });
      notify(
        'error',
        'Impossible de récupérer les données de complétion. Erreur du server, essaie plus tard ! 😓 ',
        error,
        undefined,
      );
    }
  }, [error, errorCompletion]);

  return (
    <ContextMissionDetails
      loading={loading}
      contextBloomer={data?.bloomer}
      contextMission={data?.mission}
      contextContactBloomer={data?.contactBloomerSociety}
      contextContactBillingClient={data?.contextContactBillingClient}
      contextSocietyBloomer={data?.bloomerSociety}
      contextSocietyClient={data?.clientSociety}
      contextSocietyClientBilling={data?.clientBillingSociety || data?.clientSociety}
      contextContactOperationnal={data?.contactOperations}
      error={error}
      contextMissionCompletion={data?.missionCompletion}
    />
  );
};

export default WithErrorBoundary(MissionDetails);
