import { useMemo, useState } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  gridClasses,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { styled, useTheme } from '@mui/material/styles';
import RawTableContainer from '@mui/material/TableContainer';
import {
  ContractLegalStatus,
  ContractsByMissionGQL,
  ContractType,
  LegalContractGQL,
  MissionDataForGetLegalContractsResult,
} from '@bloomays-lib/types.shared';
import { LoaderPending } from '@bloomays-lib/ui.shared';
import Modal from '../atoms/Modal';
import RawDataGrid from '../molecules/DataGrid';
import { Embed } from '../atoms/Embed';
import Tag from '../atoms/Tag';
import IconButton from '@mui/material/IconButton';
import { isEmpty, values, map, compact, pipe, flatten } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import LegalContractFile from '../molecules/LegalContractFile';

type TagContractTypeProps = {
  type: ContractType;
};

export type LegalContractRowData = LegalContractGQL &
  Omit<MissionDataForGetLegalContractsResult, 'id' | 'label'> & {
    missionId: string;
    missionLabel: string;
  };

const parseContractsByMissionGQL = (missionsContracts: ContractsByMissionGQL[]): LegalContractRowData[] => {
  const temp = map((contractByMission) => {
    const { mission, contracts } = contractByMission;
    const { bloomerId, bloomerFullName, clientSocietyId, clientSocietyName, bloomerSocietyName } = mission;

    const rows: LegalContractRowData[] = map((contract) => {
      return {
        ...contract,
        bloomerId,
        bloomerFullName,
        clientSocietyId,
        clientSocietyName,
        bloomerSocietyName,
        missionId: mission.id,
        missionLabel: mission.label,
      };
    }, contracts);
    return rows;
  }, missionsContracts);

  return pipe(flatten, compact)(temp);
};

const TagContractType = ({ type }: TagContractTypeProps) => {
  const theme = useTheme();
  return (
    <Tag
      text={type}
      aria-label={type}
      backgroundColor={type === ContractType.SST ? theme.palette.secondary.main : theme.palette.primary.main}
      color={theme.palette.common.white}
    />
  );
};

export type TableLegalContracts = {
  missionsContracts: ContractsByMissionGQL[];
};

const TableLegalContracts = ({ missionsContracts }: TableLegalContracts) => {
  const { t } = useTranslation(['legalContracts', 'mission']);
  const apiRef = useGridApiRef();
  console.log('apiRef', apiRef);

  const [fileViewerPath, setFileViewerPath] = useState<string | undefined>(undefined);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const legalContracts: LegalContractRowData[] = useMemo(
    () => parseContractsByMissionGQL(missionsContracts),
    [missionsContracts],
  );

  const groupedByMission = missionsContracts.length !== 1;
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      sorting: {
        sortModel: [{ field: 'createdAt', sort: 'asc' }],
      },
      rowGrouping: groupedByMission
        ? {
            model: ['missionLabel'],
          }
        : undefined,
    },
  });

  const contractStatus: Record<ContractLegalStatus, { value: ContractLegalStatus; label: string }> = {
    'on going': { value: 'on going', label: t('onGoing') },
    done: { value: 'done', label: t('done') },
  };
  const columns: GridColDef<LegalContractRowData>[] = [
    {
      field: 'step',
      headerName: t('step'),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    },
    {
      field: 'type',
      headerName: t('type'),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
      type: 'singleSelect',
      valueOptions: [ContractType.SST, ContractType.PDS],
      renderCell: (params) => {
        if (params.rowNode.type === 'group') {
          if (params.field !== '__row_group_by_columns_group__') return '';
          const groupingKey = params.rowNode.groupingKey as ContractType;
          if (!groupingKey) return;
          return <TagContractType type={groupingKey} />;
        }

        if (params.row.type) {
          return <TagContractType type={params.row.type} />;
        }
      },
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
      type: 'singleSelect',
      valueOptions: values(contractStatus),
      renderCell: (params) => {
        if (params.rowNode.type === 'group') {
          if (params.field !== '__row_group_by_columns_group__') return '';
          const groupingKey = params.rowNode.groupingKey as ContractLegalStatus;
          if (!groupingKey) return;
          const t = contractStatus[groupingKey];
          return (
            <IconButton size="small" title={t.label}>
              {t.label}
            </IconButton>
          );
        }

        if (params.row.status) {
          const t = contractStatus[params.row.status];
          return (
            <IconButton size="small" title={t.label}>
              {t.label}
            </IconButton>
          );
        }
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<LegalContractRowData>) => {
        return params.row.createdAt ? new Date(params.row.createdAt) : undefined;
      },
    },
    {
      field: 'signedDate',
      headerName: t('signedDate'),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<LegalContractRowData>) => {
        return params.row.signedDate ? new Date(params.row.signedDate) : undefined;
      },
    },
    {
      field: 'emptyFileUrl',
      headerName: t('emptyFileUrl'),
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      renderCell: (params: GridRenderCellParams<LegalContractRowData>) => {
        if (params.rowNode.type === 'group') {
          return '';
        }
        return (
          <LegalContractFile
            setUploadingFile={setUploadingFile}
            legalContract={params.row}
            signed={false}
            missionRecordId={params.row.missionId}
            createInvitation={true}
            onClickPreview={setFileViewerPath}
          />
        );
      },
    },
    {
      field: 'signedFileUrl',
      headerName: t('signedFileUrl'),
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      renderCell: (params: GridRenderCellParams<LegalContractRowData>) => {
        if (params.rowNode.type === 'group') {
          return '';
        }
        return (
          <LegalContractFile
            setUploadingFile={setUploadingFile}
            legalContract={params.row}
            signed={true}
            missionRecordId={params.row.missionId}
            createInvitation={false}
            onClickPreview={setFileViewerPath}
          />
        );
      },
    },
  ];

  if (groupedByMission) {
    columns.unshift({
      field: 'clientSocietyName',
      headerName: t('clientSocietyName', { ns: 'mission' }),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    });
    columns.unshift({
      field: 'bloomerSocietyName',
      headerName: t('bloomerSocietyName', { ns: 'mission' }),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    });
    columns.unshift({
      field: 'bloomerFullName',
      headerName: t('bloomerFullName', { ns: 'mission' }),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    });
    columns.unshift({
      field: 'missionLabel',
      headerName: t('label', { ns: 'mission' }),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    });
  }

  return (
    <>
      {uploadingFile && <LoaderPending text={t('uploading')} />}
      {!uploadingFile && isEmpty(legalContracts) && <>{t('noContractFounds')}</>}
      <Modal
        open={!!fileViewerPath}
        onClose={() => setFileViewerPath(undefined)}
        additionnalCSS={{
          width: '1250px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <Embed width="1200px" height="1000px" src={fileViewerPath as string} type="application/json" />
      </Modal>
      <TableContainer>
        <DataGrid
          apiRef={apiRef}
          uniqueDatagridId="table-contracts-v1"
          initialState={initialState}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          hideFooterPagination={false}
          sortingMode="client"
          filterMode="client"
          rows={legalContracts}
          getRowId={(row: LegalContractGQL) => row.recordId as string}
          columns={columns}
          pageSize={20}
          pagination={true}
          sx={{
            width: '100%',
          }}
        />
      </TableContainer>
    </>
  );
};

export default TableLegalContracts;

const DataGrid = styled(RawDataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const TableContainer = styled(RawTableContainer)(() => ({
  margin: '0 auto',
}));
