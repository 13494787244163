import { INotification, NotificationPriority, NotificationType } from '@bloomays-lib/types.shared';
import { NotificationCenterItem } from '@bloomays-lib/ui.shared';
import { TypeOptions } from 'react-toastify';

const convertNotificationPriority2TypeOptions = (priority: NotificationPriority): TypeOptions => {
  switch (priority) {
    case NotificationPriority.urgent:
      return 'error';
    case NotificationPriority.important:
      return 'warning';
    case NotificationPriority.info:
      return 'info';
    default:
      return 'default';
  }
};

export const convertINotificationToNotifcationCenterItem = (notif: INotification): NotificationCenterItem => {
  let link: string | undefined = undefined;
  if (
    notif.type === NotificationType.missingCRA ||
    notif.type === NotificationType.reminderCRA ||
    notif.type === NotificationType.signCRA
  ) {
    link = notif.mission?.length === 1 ? `/mission/${notif.mission?.[0]}` : '/missions';
  }
  if (notif.type === NotificationType.urssaf) {
    link = notif.society?.length === 1 ? `/society/${notif.society?.[0]}` : undefined;
  }
  const newNotif: NotificationCenterItem = {
    id: notif.recordId || Date.now().toString(),
    data: {
      canDelete: notif.canDelete || true,
      recordId: notif.recordId,
      link,
      title: notif.title,
    },
    read: notif.read || false,
    createdAt: new Date(notif.creationDate).getTime(),
    type: convertNotificationPriority2TypeOptions(notif.priority),
    content: notif.description,
  };
  return newNotif;
};
