import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';

type IframeProps = {
  url: string | null;
};

const StyledIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '82vh',
  border: 0,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Iframe = ({ url }: IframeProps): JSX.Element => {
  return (
    <div id="iframeElement">
      {!url ? (
        <LoaderSkeleton height={500} width={700} />
      ) : (
        <StyledIframe src={url} allow="encrypted-media" allowFullScreen></StyledIframe>
      )}
    </div>
  );
};

export default Iframe;
export { Iframe };
