import { styled } from '@mui/material/styles';
import {
  BackgroundType,
  JobTalent,
  ISkill,
  SkillTalentLevel as SkillLevelType,
  ITalent,
  ItemJobs,
  ItemSkills,
  ItemLanguages,
  IJobTitle,
} from '@bloomays-lib/types.shared';
import { EmptyState, SingleLineText } from '@bloomays-lib/ui.shared';
import { constructDateDetails } from '@bloomays-lib/utils.shared';
import ItemTalentLevelList from '../molecules/ItemTalentLevelList';
import { TitlePart } from '@bloomays-lib/ui.shared';
import AddIcon from '@mui/icons-material/Add';
import { CardItem } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import empty from '../../graphic-charter/emptyCV.svg';
import { ItemTalentLevel } from '@bloomays-lib/ui.shared';
import Modal from '../atoms/Modal';
import orderBy from 'lodash/orderBy';
import ItemsEdition from './ItemsEdition';
import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  FIND_TALENT,
  LIST_JOBS,
  DELETE_JOBS,
  UPSERT_DIRECT_SKILLS,
  UPSERT_JOBS,
  SKILLS,
} from '@bloomays-lib/adapter.api-talent';
import { useTheme } from '@mui/material/styles';
import { notify } from '../../helpers/toastify';
import XPEducationEdition from './XPEducationEdition';
import CreateIcon from '@mui/icons-material/Create';
import uniq from 'lodash/uniq';

export type TalentSheetExperienceProps = {
  talent?: ITalent;
};

const TalentSheetExperience = ({ talent }: TalentSheetExperienceProps) => {
  const theme = useTheme();

  const { t } = useTranslation(['talentSheet', 'random']);
  // Les direct skills existant du talent
  const [skills, setSkills] = useState<ItemSkills[]>();
  const [newSkills, setNewSkills] = useState<ItemSkills[]>([]);
  const [experience, setExperience] = useState<JobTalent>({
    id: '',
    current: false,
    type: BackgroundType.Job,
    name: '',
    jobTitle: undefined,
    talentId: talent?.id,
  });
  // Les skills iso en BDD
  const [listItemsSkill, setListItemsSkill] = useState<string[]>([]);
  const [listItemsJob, setListItemsJob] = useState<string[]>([]);
  const [open, setOpen] = useState<{
    items: ItemJobs[] | ItemSkills[] | ItemLanguages[];
    show: boolean;
    type: 'skills' | 'languages';
  }>({ items: [], show: false, type: 'skills' });
  const [openXP, setOpenXP] = useState<{
    item: JobTalent | undefined;
    show: boolean;
  }>({ item: undefined, show: false });

  const { data: dataSkills } = useQuery<{ listSkills: ISkill[] }>(SKILLS, {
    context: { clientName: 'api.talents' },
    variables: { iso: true },
  });

  const { data: dataJobs } = useQuery<{ listJobTitles: IJobTitle[] }>(LIST_JOBS, {
    variables: {
      iso: true,
    },
    context: { clientName: 'api.talents' },
  });

  const [upsertSkills, { loading: loadingSkills }] = useMutation<{
    upsertDirectSkills: SkillLevelType[];
  }>(UPSERT_DIRECT_SKILLS, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: talent?.id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const [upsertJobTalent, { loading: loadingJobTalent }] = useMutation<{
    upsertJobTalent: JobTalent;
  }>(UPSERT_JOBS, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: talent?.id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const [deleteJobTalent] = useMutation<{
    deleteJobTalent: JobTalent;
  }>(DELETE_JOBS, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: talent?.id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const handleClose = () => {
    setOpen({ ...open, show: false });
    setSkills(skills?.filter((skill) => skill.label !== ''));
  };

  const handleCloseXP = () => {
    setOpenXP({ ...openXP, show: false });
  };

  useEffect(() => {
    if (talent) {
      // Les direct skills existant du talent
      setSkills(
        talent.skills?.map((skill) => {
          return {
            id: skill.id as string,
            skillId: skill.skill.id,
            label: skill.skill.label,
            level: skill.level,
            selected: true,
          };
        }),
      );
    }
  }, [talent]);

  useEffect(() => {
    if (dataSkills?.listSkills) {
      // Les skills iso en BDD
      const isoSkills = dataSkills?.listSkills?.map((skill) => skill.label) || [];
      const nonIsoSkills = skills?.map((skill) => skill.label) || [];
      setListItemsSkill(uniq([...isoSkills, ...nonIsoSkills]));
    }
  }, [dataSkills, skills]);

  useEffect(() => {
    if (dataJobs?.listJobTitles) {
      // Les jobs iso en BDD
      setListItemsJob(dataJobs?.listJobTitles?.map((job) => job.label));
    }
  }, [dataJobs]);

  const upsertdirectSkills = async () => {
    const preparedSkills = newSkills.map((skill) => {
      return {
        id: skill.id,
        skill: { label: skill.label, id: skill.skillId },
        level: skill.level,
        talentId: talent?.id,
        isToDelete: skill?.isToDelete,
      };
    });
    const updated = await upsertSkills({
      variables: {
        directSkills: preparedSkills,
      },
    });
    if (updated.data?.upsertDirectSkills && updated.data?.upsertDirectSkills?.length > 0) {
      notify('success', t('modifSaved', { ns: 'random' }), null, {
        hideProgressBar: false,
        autoClose: 3000,
      });
      handleClose();
    }
  };

  const upsertJob = async (xp: JobTalent) => {
    const updated = await upsertJobTalent({
      variables: {
        jobTalent: { ...xp, talentId: talent?.id },
      },
    });
    if (updated.data?.upsertJobTalent && updated.data?.upsertJobTalent?.id) {
      notify('success', t('modifSaved', { ns: 'random' }), null, {
        hideProgressBar: false,
        autoClose: 3000,
      });
      handleCloseXP();
    }
  };

  const deleteJob = async (id?: string) => {
    const deleted = await deleteJobTalent({
      variables: {
        jobTalentId: id,
      },
    });
    if (deleted.data?.deleteJobTalent && deleted.data?.deleteJobTalent?.id) {
      notify('success', t('modifSaved', { ns: 'random' }), null, {
        hideProgressBar: false,
        autoClose: 3000,
      });
      handleCloseXP();
    }
  };

  return (
    <div>
      <Modal
        open={open.show}
        onClose={handleClose}
        additionnalCSS={{
          border: 'none',
          borderRadius: '20px',
          backgroundColor: theme.palette.grey[200],
          overflowY: 'auto',
        }}
      >
        <ItemsEdition
          talentId={talent?.id}
          loading={loadingSkills}
          items={skills || []}
          type={open.type}
          setNewRow={setSkills}
          setNews={setNewSkills}
          upsert={upsertdirectSkills}
          itemList={listItemsSkill}
        />
      </Modal>
      <Modal
        open={openXP.show}
        onClose={handleCloseXP}
        additionnalCSS={{
          border: 'none',
          borderRadius: '20px',
          backgroundColor: theme.palette.grey[200],
          overflowY: 'auto',
        }}
      >
        <XPEducationEdition
          loading={loadingJobTalent}
          item={openXP.item}
          type={'XP'}
          upsert={upsertJob}
          deleteXP={deleteJob}
          itemList={listItemsJob}
          itemListSkill={listItemsSkill}
        />
      </Modal>
      <StyledXP>
        <StyledContainerSkillDetails>
          <TitlePart variant="subtitle2Medium" textTitle={t('description', { ns: 'talentSheet' })} />
          {talent?.summary ? (
            <StyledCalculInformation>
              <SingleLineText text={talent.summary} />
            </StyledCalculInformation>
          ) : (
            <StyledEmptyState>
              <SingleLineText text={t('noDescription')} />
            </StyledEmptyState>
          )}
        </StyledContainerSkillDetails>
      </StyledXP>
      <StyledXP>
        <StyledContainerSkillDetails>
          <TitlePart variant="subtitle2Medium" textTitle={t('skills', { ns: 'talentSheet' })} />
          <StyledRowContainerAdd
            onClick={() => {
              setSkills(
                talent?.skills?.map((skill) => {
                  return {
                    id: skill.id as string,
                    skillId: skill.skill.id,
                    label: skill.skill.label,
                    level: skill.level,
                    selected: true,
                  };
                }),
              );
              setOpen({
                type: 'skills',
                items: skills || [],
                show: true,
              });
            }}
          >
            <AddIcon />
            <SingleLineText variant={'body2Medium'} text={t('addSkill', { ns: 'talentSheet' })} />
          </StyledRowContainerAdd>
          {talent?.skillLevels && talent?.skillLevels.length > 0 ? (
            <>
              <StyledCalculInformation>
                <SingleLineText text={t('explainCalcul', { ns: 'talentSheet' })} />
              </StyledCalculInformation>
              <ItemTalentLevelList items={talent?.skillLevels || []} />
            </>
          ) : (
            <StyledEmptyState>
              <EmptyState image={empty} text={t('noSkills')} />
            </StyledEmptyState>
          )}
        </StyledContainerSkillDetails>
      </StyledXP>

      <StyledXP>
        <TitlePart variant="subtitle2Medium" textTitle={t('jobs', { ns: 'talentSheet' })} />
        <StyledRowContainerAdd
          onClick={() => {
            setOpenXP({
              item: experience,
              show: true,
            });
          }}
        >
          <AddIcon />
          <SingleLineText variant={'body2Medium'} text={t('addXP', { ns: 'talentSheet' })} />
        </StyledRowContainerAdd>
        {talent?.jobs && talent?.jobs.length > 0 ? (
          orderBy(talent?.jobs, (item) => item?.period?.startDate, ['desc'])?.map((job, i) => {
            return (
              <div key={job.id}>
                <StyledRowContainerAdd
                  onClick={() => {
                    setOpenXP({
                      item: job,
                      show: true,
                    });
                  }}
                >
                  <CreateIcon />
                </StyledRowContainerAdd>
                <StyledContainerXP>
                  <CardItem
                    variant={'blue'}
                    size={'medium'}
                    emoji={<SingleLineText text={job?.name?.substring(0, 1)} />}
                  />

                  <StyledContainerXPDetails>
                    <StyledContainerJobTitle>
                      {job?.jobTitle &&
                        job?.jobTitle?.map((j, i) => {
                          return (
                            <>
                              <StyledTitles key={`${j.label}-${i}`} variant={'subtitle2Medium'} text={j.label} />

                              {i < (job?.jobTitle?.length as number) - 1 && '/'}
                            </>
                          );
                        })}
                    </StyledContainerJobTitle>
                    <StyledTitles variant={'subtitle2Medium'} text={job?.name} />
                    <StyledDateText variant={'subtitle1'} text={constructDateDetails(job.period, job?.current)} />
                    <SingleLineText variant={'body1Light'} text={job?.description} />
                    <StyledContainerSkill>
                      {job?.skills?.map((value: ISkill) => (
                        <ItemTalentLevel
                          key={value.id}
                          skill={{
                            label: value.label,
                          }}
                        />
                      ))}
                    </StyledContainerSkill>
                  </StyledContainerXPDetails>
                </StyledContainerXP>
                {talent.jobs && talent.jobs?.length - 1 !== i && <StyledBarXP></StyledBarXP>}
              </div>
            );
          })
        ) : (
          <StyledEmptyState>
            <EmptyState image={empty} text={t('noXp')} />
          </StyledEmptyState>
        )}
      </StyledXP>
    </div>
  );
};

export default TalentSheetExperience;
export { TalentSheetExperience };

const StyledEmptyState = styled('div')(
  () => `
  display: flex;
  justify-content: center;
  padding-top: 24px;
  `,
);

const StyledBarXP = styled('div')(
  ({ theme }) => `
  height: 0px;
  margin-top: 32px;
  border: 1px solid ${theme.palette.grey[400]};
  `,
);

const StyledXP = styled('div')(
  () => `
  box-shadow: 0px 4.30769px 23.6923px rgba(75, 75, 75, 0.09);
  border-radius: 10px;
  text-align: left;
  padding: 24px 24px 32px 24px;
  margin-bottom: 24px;
  `,
);

const StyledContainerXPDetails = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  max-width: 1280px;
  gap: 8px;
  overflow: hidden;
  `,
);

const StyledContainerSkill = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap; 
  gap: 8px;
  `,
);

const StyledContainerSkillDetails = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap; 
  gap: 12px;
  `,
);

const StyledContainerJobTitle = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: flex-start;
  `,
);

const StyledContainerXP = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  align-items: flex-start;
  margin-top: 32px;
  `,
);

const StyledDateText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
  `,
);

const StyledTitles = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[100]};
  `,
);

const StyledCalculInformation = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background-color: ${theme.palette.grey[200]};
  border-radius: 10px;
  `,
);

const StyledRowContainerAdd = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
  color: ${theme.palette.grey[100]};
  float: right;

  &:hover {
    cursor: pointer;
  }
  `,
);
