import jwt_decode from 'jwt-decode';
import { AnonymousJwtPayload, Auth0UserType, GetAuth0User0 } from '@bloomays-lib/types.shared';

export const GetAnonymousUser = (
  token: string | null,
  logout: () => void,
  loginWithRedirect: () => void,
): Auth0UserType => {
  if (token && token.includes('anonymous:')) {
    try {
      const decodedToken: AnonymousJwtPayload = jwt_decode(token);
      const context = {
        user: decodedToken.user,
        isAuthenticated: true,
        isLoading: false,
        error: null,
        logout: logout,
        loginWithRedirect: loginWithRedirect,
      };
      delete decodedToken.user;
      if (context.user) {
        context.user.sub = decodedToken;
        context.user.email_verified = true;
        context.user.token = token;
      }
      return context;
    } catch (error: any) {
      return {
        user: null,
        isAuthenticated: false,
        isLoading: false,
        error,
        logout,
        loginWithRedirect,
      };
    }
  }
  return {
    error: new Error('No token found'),
    user: null,
    isAuthenticated: false,
    isLoading: false,
    logout,
    loginWithRedirect,
  };
};

export const GetAuth0User = ({
  user,
  isLoading,
  isAuthenticated,
  error,
  getAccessTokenSilently,
  loginWithRedirect,
  logout,
}: Auth0UserType): Auth0UserType => {
  if (user) {
    user.roles = user?.['https://www.bloomays.com/roles'] || [];
    user.displayName = user.name || user.displayName;
    delete user.name;
  }
  return {
    user,
    isLoading,
    isAuthenticated,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  };
};

export const UserFactory = ({
  useQuery,
  user0,
  isAuthenticated0,
  isLoading0,
  error0,
  location,
  getAccessTokenSilently0,
  loginWithRedirect0,
  logout0,
}: GetAuth0User0): Auth0UserType => {
  const query = useQuery(new URL(location));
  const qToken = query.get('token');
  if (qToken) {
    sessionStorage.setItem('token', qToken);
  }
  const token = qToken || sessionStorage.getItem('token') || null;
  const { user, isLoading, isAuthenticated, error, logout, loginWithRedirect } = GetAnonymousUser(
    token,
    logout0,
    loginWithRedirect0,
  );
  if (!isAuthenticated) {
    return GetAuth0User({
      user: user0,
      isAuthenticated: isAuthenticated0,
      isLoading: isLoading0,
      error: error0,
      getAccessTokenSilently: getAccessTokenSilently0,
      logout: logout0,
      loginWithRedirect: loginWithRedirect0,
    });
  }
  return { user, isLoading, isAuthenticated, error, logout, loginWithRedirect };
};
